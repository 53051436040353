.closed-slider {

}

.c-d-block {
  background: #fff;
  -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.3);
  -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.3);
  box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.3);
  height: 225px;
  width: 85%;
}

.slick-slider {
  margin-left: 50px;
  margin-right: 50px;
}

.slick-prev:before,
.slick-next:before {
  color: black!important;
}

.closed-slider img {
  max-height: 60px;
  max-width: 150px;
}

.slick-list {
  height: 250px;
}

.c-info {
  width: 100%;
  font-size: 90%;
}

.company-logo {
  white-space: normal !important;
  text-align: center;
  width: 100%;
  padding-top: 20px;
  height: 80px;
}

.slick-dots {
  display: none !important;
}
