/* general setting
-----------------------------------------------------*/
* {
  outline: transparent !important;
}

body {
  margin: -1px !important;
  font-size: 16px;
  color: #004466;
  line-height: 1.75;
  background: #fff;
  font-family: "Poppins", sans-serif;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

a {
  color: #006aff;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
a:hover {
  text-decoration: none;
  color: #006aff;
}
a:focus {
  outline: none;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  line-height: 1.28;
}

h1 {
  font-size: 40px;
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 24px;
    line-height: 1.2em;
  }
}

h2 {
  font-size: 32px;
}

h3 {
  font-size: 20px;
}

p {
  margin-bottom: 22px;
}
p:last-child {
  margin-bottom: 0px;
}

input[type="submit"] {
  border: none;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.page-header {
  margin: 0;
  padding: 0;
  border: none;
}

.page-main {
  min-height: 720px;
}

@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}
/* header -  desktop view
  -----------------------------------------------------*/
.smd-search {
  position: relative;
}
.smd-search .form-control {
  padding-right: 40px;
}
.smd-search .btn-search {
  position: absolute;
  background: url(../images/ico-search.png) no-repeat center top 14px;
  width: 42px;
  height: 48px;
  right: 0px;
  top: 0px;
  border: none;
  cursor: pointer;
  padding: 0;
}

.md-header-desktop {
  padding: 18px 0px 10px !important;
  box-shadow: 0px 2px 12px #0000001a;
}
.md-header-desktop .logo {
  margin-left: 0px !important;
  /* float: left; */
}
.md-header-desktop .main-nav-desktop {
  /* float: right; */
  margin-left: 137px;
  font-size: 14px;
  font-family: "Poppins";
  margin-top: 2px;
}
.md-header-desktop .main-nav-desktop ul {
  float: left;
  padding: 6px 30px 0px;
}
.md-header-desktop .main-nav-desktop ul li {
  float: left;
  margin: 0px 12px;
}
.md-header-desktop .main-nav-desktop ul li a {
  color: #002233;
  font-size: 14px;
  padding: 0 10px;
}
.md-header-desktop .main-nav-desktop ul li a:hover {
  color: #006aff;
}
.md-header-desktop .main-nav-desktop ul li.current-menu-item a {
  color: #006aff;
}
.md-header-desktop .main-nav-desktop .search {
  margin: 10px 35px 0px 25px;
  float: left;
  position: relative;
}
.md-header-desktop .main-nav-desktop .search .toggle-search span {
  width: 19px;
  height: 19px;
  display: block;
  background: url(../images/ico-search.png) no-repeat center center;
  cursor: pointer;
}
.md-header-desktop .main-nav-desktop .search .smd-search {
  position: absolute;
  top: 52px;
  width: 220px;
  right: -11px;
  opacity: 0;
  z-index: -10;
  -webkit-transition-duration: 0.5s;
  -o-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.md-header-desktop .main-nav-desktop .search .smd-search .form-control {
  background: #eee;
}
.md-header-desktop .main-nav-desktop .search.active .smd-search {
  opacity: 1;
  z-index: 10;
  top: 42px;
}
.md-header-desktop .main-nav-desktop .account {
  display: inline-block;
}
.md-header-desktop .main-nav-desktop .account a {
  font-size: 13px;
  width: 124px;
  border-radius: 2px;
}

/* header -  mobile view
  -----------------------------------------------------*/
body.navbar-open {
  -webkit-transform: translateX(-280px);
  -ms-transform: translateX(-280px);
  -o-transform: translateX(-280px);
  transform: translateX(-280px);
  overflow: hidden;
  position: relative;
}
body.navbar-open:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0px;
  z-index: 50;
}
body.navbar-open .toggle-menu {
  z-index: 9999;
}

.md-header-mobile {
  padding: 10px 0px 10px;
  -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
}
.md-header-mobile .ctn-inner {
  position: relative;
}
.md-header-mobile .top-navtigation .toggle-menu {
  position: absolute;
  right: 0px;
  top: 12px;
  width: 24px;
  height: 18px;
  cursor: pointer;
}
.md-header-mobile .top-navtigation .toggle-menu .icon {
  position: relative;
}
.md-header-mobile .top-navtigation .toggle-menu .icon span {
  display: block;
  width: 24px;
  height: 2px;
  background: #006aff;
  position: absolute;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  border-radius: 1px;
}
.md-header-mobile .top-navtigation .toggle-menu .icon span:nth-child(2) {
  top: 7px;
}
.md-header-mobile .top-navtigation .toggle-menu .icon span:nth-child(3) {
  top: 14px;
}
.md-header-mobile .top-navtigation .toggle-search span {
  position: absolute;
  right: 40px;
  top: 10px;
  background: url(../images/ico-search.png) no-repeat center center;
  width: 19px;
  height: 19px;
  display: block;
  border: none;
  padding: 0;
  cursor: pointer;
}
.md-header-mobile .main-nav-mobile {
  -webkit-transition: all 350ms ease-out;
  -o-transition: all 350ms ease-out;
  transition: all 350ms ease-out;
  background: #f7f7f7;
  display: block;
  height: 100vh;
  margin: 0;
  overflow-y: auto;
  padding: 0;
  position: fixed;
  right: -280px;
  left: auto;
  top: 0;
  bottom: 0;
  visibility: visible;
  width: 280px;
  z-index: 1050;
}
.md-header-mobile .main-nav-mobile ul {
  padding: 3px 0px 15px;
}
.md-header-mobile .main-nav-mobile ul li a {
  color: rgba(102, 102, 102, 0.85);
  background-color: transparent;
  border-bottom: 1px solid #ececec;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.6;
  padding: 17px 15px 18px 15px;
  display: block;
}
.md-header-mobile .main-nav-mobile ul li a:hover {
  color: #666666;
}
.md-header-mobile .main-nav-mobile .smd-search .form-control {
  background: #ddd;
}
.md-header-mobile.active .top-navtigation .toggle-menu .icon span {
  background: #ffffff;
}
.md-header-mobile.active .top-navtigation .toggle-menu .icon span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 8px;
}
.md-header-mobile.active .top-navtigation .toggle-menu .icon span:nth-child(2) {
  display: none;
}
.md-header-mobile.active .top-navtigation .toggle-menu .icon span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 8px;
}

/* header loggedin page
  -----------------------------------------------------*/
.page-header-loggedin {
  border-bottom: 1px solid #eef4f8;
  padding: 15px 0px 14px;
  background: #fff;
  height: 72px;
}
.page-header-loggedin .logo {
  float: left;
  margin: 3px 0px 0px;
}
.page-header-loggedin .account {
  float: right;
  cursor: pointer;
}
.page-header-loggedin .account .search {
  float: left;
  margin: 11px 37px 0px 0px;
  cursor: pointer;
}
.page-header-loggedin .account .search .toggle-search span {
  width: 19px;
  height: 19px;
  display: block;
  background: url(../images/ico-search-gray.png) no-repeat center center;
  cursor: pointer;
}
.page-header-loggedin .account .notification {
  float: left;
  margin: 10px 31px 0px 0px;
  cursor: pointer;
}
.page-header-loggedin .account .notification .toggle-notification span {
  width: 20px;
  height: 22px;
  display: block;
  background: url(../images/ico-alert.png) no-repeat center center;
  cursor: pointer;
}
.page-header-loggedin .account .avatar {
  float: right !important;
  margin-left: 20px !important;
}
.page-header-loggedin .account .avatar img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}
@media screen and (max-width: 480px) {
  .page-header-loggedin .account .search {
    margin: 11px 22px 0px 0px;
  }
  .page-header-loggedin .account .notification {
    margin: 10px 20px 0px 0px;
  }
}

/* footer -  desktop view
  -----------------------------------------------------*/
.smd-social .inner-social {
  display: table-cell;
  vertical-align: middle;
}
.smd-social ul li {
  line-height: 1.5;
  margin-right: 14px;
  display: block;
  float: left;
}
.smd-social ul li:last-child {
  margin-right: 0px;
}

.page-footer {
  background: #1d2839;
  padding: 48px 0 38px !important;
}
.page-footer .top-footer {
  margin-bottom: 0px;
}
.page-footer .top-footer .logo-footer {
  text-align: center;
  margin: 0px 0px 21px;
}
.page-footer .top-footer .menu {
  margin: 0px 0px 21px;
  width: 100%;
  text-align: center;
}
.page-footer .top-footer .menu ul {
  display: inline-block;
}
.page-footer .top-footer .menu ul li {
  line-height: 1.43;
  display: block;
  margin: 0px 33px 0px 0px;
  float: left;
}
.page-footer .top-footer .menu ul li:last-child {
  margin: 0px 0px 0px 0px;
}
.page-footer .top-footer .menu ul li a {
  font-size: 14px;
  line-height: 20px;
  color: #002233;
}
.page-footer .top-footer .social {
  margin: 0px 0px 26px;
}
.page-footer .top-footer .social .inner-social {
  display: table;
  margin: 0 auto;
}
.page-footer .top-footer .social .title {
  padding: 2px 18px 0px 0px;
  font-size: 14px;
  line-height: 1.43;
  color: #002233;
  display: table-cell;
  vertical-align: middle;
}
.page-footer .top-footer .social .smd-social {
  display: table-cell;
  vertical-align: middle;
}
.page-footer .bottom-footer {
  text-align: center;
}
.page-footer .bottom-footer .copyright {
  font-size: 14px;
  line-height: 1.43;
}
@media (max-width: 767px) {
  .page-footer .top-footer .menu ul li {
    display: inline-block;
    float: none;
    margin: 0px 10px 0px 10px;
  }
}
@media (min-width: 768px) {
  .page-footer .top-footer {
    margin: 0px 0px 13px;
  }
  .page-footer .top-footer .logo-footer {
    text-align: left;
  }
  .page-footer .top-footer .menu {
    float: right;
    text-align: right;
    padding: 8px 0px 0px;
  }
  .page-footer .top-footer .social {
    float: right;
    padding: 5px 0px 0px;
  }
  .page-footer .bottom-footer {
    text-align: left;
  }
}
@media (min-width: 1215px) {
  .page-footer .top-footer .col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 60%;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
  }
  .page-footer .top-footer .col-md-4.col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }
  .page-footer .top-footer .menu {
    padding-right: 39px;
  }
}

/* general component setting   
  -----------------------------------------------------*/
.btn {
  /* min-height: 40px;
    border-radius: 2px; */
  background-color: #0081e4;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.71;
  color: #ffffff;
  padding: 8px 20px 7px 20px;
  /* line-height: 1.71;
    border: none;
    height: auto;
    white-space: normal; */
}
.btn:hover {
  color: #ffffff;
  background: #6a97ff;
}
.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn.btn-medium {
  min-height: 40px;
  padding: 10px 20px 10px 20px;
  font-size: 15px;
}
.btn.btn-lg {
  min-height: 58px;
  padding: 18px 20px 15px 20px;
}
.btn.btn-outline-primary {
  background: #0081e4;
  border: 1px solid #006aff;
  color: #fff;
  font-weight: 400;
}
.btn.btn-outline-primary:hover {
  color: #ffffff;
  background: #006aff;
  border: 1px solid #006aff;
}
.btn.btn-normal {
  padding: 13px 16px 12px 16px;
}
.btn.btn-normal.btn-plus {
  background-image: url(../images/ico-plus.png);
  background-position: right 19px top 16px;
}
.btn.btn-plus {
  background-image: url(../images/ico-plus.png);
  background-repeat: no-repeat;
  background-position: right 19px top 12px;
  padding-right: 49px;
}
.btn.btn-filter {
  background-image: url(../images/ico-filter.png);
  background-repeat: no-repeat;
  background-position: right 19px top 18px;
  padding-right: 49px;
  padding-left: 17px;
}
.btn.btn-signup {
  background-image: url(../images/ico-envelop.png);
  background-repeat: no-repeat;
  background-position: right 18px top 16px;
  padding-right: 49px;
  padding-left: 17px;
}

.no-wrap {
  white-space: nowrap;
}

.is-checkbox .ui.checkbox {
  overflow: hidden;
}
.is-checkbox .ui.checkbox label {
  color: #002233;
  padding-left: 30px;
}
.is-checkbox .ui.checkbox label:before {
  width: 20px;
  height: 20px;
  border: 1px solid #6e8b9a !important;
  background: #fafbfc;
  border-radius: 2px;
  -webkit-box-shadow: inset 0 0 2px #000;
  box-shadow: inset 0 0 2px #000;
}
.is-checkbox .ui.checkbox.checked label:before {
  border: 1px solid #006aff !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.is-checkbox .ui.checkbox.checked label:before {
  background: #006aff !important;
}
.is-checkbox .ui.checkbox.checked label:after {
  left: 4px;
  width: 12px;
  height: 9px;
  content: "";
  display: block;
  background: url(../images/ico-checked.png) no-repeat center center;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  top: 6px;
}

.form-control {
  height: 44px;
  border-radius: 2px;
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  border: none;
  color: #002233;
  font-size: 14px;
  border: 1px solid #e2e2e2;
}
.form-control.form-control-textarea {
  height: 144px;
}
.form-control:focus {
  background-color: #fff;
  font-family: "Poppins", sans-serif;
  border: none;
  color: #002233;
  font-size: 14px;
  border: 1px solid #e2e2e2;
}
.form-control.date-time {
  background-image: url(../images/ico-datetime.png);
  background-repeat: no-repeat;
  background-position: right 15px center;
}

@media screen and (max-width: 991px) {
  .page-main {
    padding: 48px 0px 52px;
  }
}

.page-signup h1 {
  line-height: 1.5;
  color: #002233;
}
.page-signup form .forgot-password .field {
  margin: 0px 0px 20px;
}

/* overwrite boostrap select  
  -----------------------------------------------------*/
.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 336px;
  display: block;
}

.bootstrap-select .dropdown-menu li a span.check-mark {
  display: block;
}
.bootstrap-select .dropdown-menu li a span.check-mark:before {
  display: block;
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  width: 20px;
  height: 20px;
  border: 2px solid #6e8b9a;
  background: #fafbfc;
  border-radius: 1px;
}
.bootstrap-select .dropdown-menu li a span.check-mark:after {
  display: none;
  position: absolute;
  left: 14px;
  top: 5px;
  width: 12px;
  height: 9px;
  content: "";
  border: 0px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  background: url(../images/ico-checked.png) no-repeat center center;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  position: absolute;
  left: 0px;
  right: auto;
}

.bootstrap-select .dropdown-menu li a.selected span.check-mark:after {
  display: block;
}
.bootstrap-select .dropdown-menu li a.selected span.check-mark:before {
  position: absolute;
  left: 10px;
  background: #006aff;
  border: none;
}

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  padding-left: 20px;
}

.bootstrap-select .btn {
  background: #eef2e5;
  padding: 13px 20px 9px 8px;
  height: 48px;
}
.bootstrap-select.show .dropdown-toggle::after {
  background: url(../images/ico-arrow-up.png) no-repeat;
}
.bootstrap-select.show .dropdown-toggle .filter-option-inner-inner {
  color: #002233;
}
.bootstrap-select .dropdown-toggle .filter-option {
  background: #f4f5f6;
}
.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  color: #6e8b9a;
}
.bootstrap-select .dropdown-toggle::after {
  width: 12px;
  height: 8px;
  background: url(../images/ico-arrow-down.png) no-repeat;
  border: 0px;
  position: absolute;
  right: 14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
  }

  .bootstrap-select .dropdown-menu {
    min-width: 100%;
    width: -webkit-calc(100% + 2px);
    width: calc(100% + 2px);
    left: -1px !important;
  }
}
/* choose kind of account for signup
  -----------------------------------------------------*/
.page-choose-account .top-content {
  margin-bottom: 79px;
}
.page-choose-account .top-content h1 {
  line-height: 1.5;
  margin-bottom: 17px;
}
.page-choose-account .main-content .col-md-4 .ctn-inner {
  padding: 67px 32px 73px 32px;
  background: #ffffff;
  border-radius: 8px;
  text-align: center;
}
.page-choose-account .main-content .col-md-4 .ctn-inner:hover {
  -webkit-box-shadow: 0 52px 68px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 52px 68px 0 rgba(0, 0, 0, 0.04);
}
.page-choose-account .main-content .col-md-4 .ctn-inner h3 {
  color: #002233;
  font-size: 20px;
  margin-bottom: 19px;
}
.page-choose-account .main-content .col-md-4 .ctn-inner .excerpt {
  margin-bottom: 71px;
  color: #004466;
  max-width: 488px;
}
.page-choose-account .main-content .col-md-4 .ctn-inner .excerpt p {
  line-height: 1.38;
}
.page-choose-account .main-content .col-md-4 .ctn-inner .button-group {
  width: 100%;
}
.page-choose-account .main-content .col-md-4 .ctn-inner .button-group a {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .page-choose-account .top-content {
    margin-bottom: 50px;
  }
  .page-choose-account .top-content h1 {
    font-size: 24px;
    line-height: 1.2em;
  }
  .page-choose-account .main-content .col-md-4 {
    margin-bottom: 30px;
  }
  .page-choose-account .main-content .col-md-4 .ctn-inner {
    padding: 37px 32px 65px 32px;
  }
  .page-choose-account .main-content .col-md-4 .ctn-inner .excerpt {
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 992px) {
  .page-choose-account {
    padding: 24px 0px 132px;
  }
}

/* create account
  -----------------------------------------------------*/
.smd-file-detail .file-image {
  width: 32px;
  height: 32px;
  display: block;
  float: left;
  margin: 0px 13px 0px 0px;
}
.smd-file-detail .file-meta {
  float: left;
  padding: 0px 0px 0px 0px;
}
.smd-file-detail .file-meta .name {
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #002233;
}
.smd-file-detail .file-meta .view {
  font-size: 14px;
  line-height: 1.43;
}
.smd-file-detail .file-meta .view a {
  background: url(../images/ico-eye-view.png) no-repeat left top 4px;
  padding: 0px 0px 0px 27px;
}
.smd-file-detail .file-meta .view .detete {
  background: url(../images/ico-delete.png) no-repeat left top 1px;
  padding: 0px 0px 0px 26px;
  cursor: pointer;
  margin: 0px 0px 0px 24px;
}

.form-normal h1 {
  margin-bottom: 32px;
}
.form-normal form {
  border-radius: 8px;
  -webkit-box-shadow: 0 52px 68px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 52px 68px 0 rgba(0, 0, 0, 0.04);
  background: #ffffff;
  padding: 36px 48px 65px 48px;
  /* width: 624px; */
}
.form-normal form .is-radio .ui.radio.checkbox label:before {
  border: 2px solid #6e8b9a;
  width: 20px;
  height: 20px;
  top: -2px;
}
.form-normal form .is-radio input:checked ~ label:after {
  width: 10px !important;
  height: 10px !important;
  background-color: #006aff !important;
  -webkit-transform: scale(1) !important;
  -ms-transform: scale(1) !important;
  -o-transform: scale(1) !important;
  transform: scale(1) !important;
  left: 5px;
  top: 3px;
}
.form-normal form .is-radio .ui.radio.checkbox input:checked ~ label:before {
  border: 2px solid #006aff;
}
.form-normal form .is-radio .ui.radio.checkbox label {
  padding-left: 30px;
  margin-bottom: 9px;
  color: #004466;
}
.form-normal form .is-radio .ui.radio.checkbox.checked label {
  color: #006aff !important;
}
.form-normal form .field {
  margin-bottom: 27px;
}
/* .form-normal form .field .form-control {
  padding: 0.375rem 8px;
  border: 1px solid #e2e2e2 !important;
} */
.form-normal form .field label.title {
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
  color: #6e8b9a;
}
.form-normal form .field label.is-tooltip {
  background: url(../images/ico-tooltip.png) no-repeat right 5px center;
  padding-right: 27px;
}
.form-normal form .field .input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.form-normal form .field .field-input {
  position: relative;
}
.form-normal form .field .field-input .btn-toggle {
  cursor: pointer;
  width: 20px;
  height: 15px;
  background: url(../images/ico-eye-show.png) no-repeat center center;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  display: block;
  position: absolute;
  top: 50%;
  right: 14px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.form-normal form .field .field-input .btn-toggle.hide {
  background: url(../images/ico-eye-hide.png) no-repeat center center;
}
.form-normal form .field .field-input.is-field-upload {
  border: 2px dashed #f4f5f6;
  padding: 34px 20px 30px 20px;
  text-align: center;
  border-radius: 2px;
}
.form-normal form .field .field-input.is-field-upload .text-upload {
  margin: 0 auto 16px auto;
  max-width: 250px;
}
.form-normal form .field .field-input.is-field-upload .text-upload .icon {
  margin-bottom: 12px;
}
.form-normal form .field .field-input.is-field-upload .text-upload p {
  font-size: 14px;
  line-height: 1.43;
}
.form-normal form .field .field-input.is-field-upload .btn {
  margin: 0;
}
.form-normal form .field .field-input.is-field-upload .btn-outline-primary {
  color: #006aff;
  min-width: 188px;
}
.form-normal
  form
  .field
  .field-input.is-field-upload
  .btn-outline-primary:hover {
  color: #ffffff;
  background: #006aff;
  border: 1px solid #006aff;
}
.form-normal form .field .is-checkbox {
  padding: 0px 0px 13px;
}
.form-normal form .field.hl-margin .is-checkbox {
  padding: 0px 0px 0px;
}
.form-normal form .field.field-button {
  padding: 5px 0px 0px;
}
.form-normal form .button-group {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.form-normal form .button-group .row {
  width: 100%;
}
.form-normal form .button-group button {
  width: 100%;
}
@media screen and (max-width: 575px) {
  .form-normal h1 {
    margin-bottom: 20px;
  }
  .form-normal form {
    padding: 36px 20px 65px 20px;
  }
  .form-normal form .button-group {
    display: block;
  }
  .form-normal form .button-group .btn {
    margin: 0px 0px 20px;
  }
  .form-normal form .button-group .col-sm-6:last-child .btn {
    margin: 0px 0px 0px;
  }
}
@media screen and (min-width: 768px) {
  .form-normal .button-group.w-small .col-sm-6 {
    max-width: 244px;
    min-width: 244px;
    width: auto;
    padding: 0px 12px 0px 12px;
  }
}

.page-login-account .form-login-account {
  max-width: 624px;
  margin: 0 auto;
}

.page-create-account .form-create-account {
  max-width: 800px;
  margin: 0 auto;
}
.page-create-account.brocker-account .form-normal form .field {
  margin-bottom: 28px;
}
.page-create-account.brocker-account .form-normal form .hl-margin {
  margin-bottom: 5px;
}
.page-create-account.brocker-account
  .form-normal
  form
  .field-input.is-field-upload {
  padding: 47px 20px 46px 20px;
  margin: 3px 0px 0px;
}
.page-create-account.investor-account .form-normal form .field.hl-margin {
  margin-bottom: 34px;
}
.page-create-account.investor-account .form-normal form .field.hl-margin-1 {
  margin-bottom: 41px;
}
@media screen and (max-width: 767px) {
  .page-create-account.investor-account .form-normal form .button-group {
    margin-bottom: 15px;
  }
  .page-create-account.investor-account .form-normal form .button-group button {
    padding: 16px 15px 15px 15px;
  }
}
@media screen and (min-width: 992px) {
  .page-create-account.investor-account {
    padding: 24px 0px 144px 0px;
  }
  .page-create-account.investor-account.investor-account {
    padding: 24px 0px 159px 0px;
  }
}
@media screen and (min-width: 992px) {
  .page-create-account {
    padding: 24px 0px 144px 0px;
  }
  .page-create-account.investor-account {
    padding: 24px 0px 159px 0px;
  }
}

.page-create-investor-account .form-normal form .field-group-item {
  margin-bottom: 33px;
}
.page-create-investor-account .form-normal form .field-group-item .hl-title {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  line-height: 1.5;
  margin-bottom: 15px;
}
.page-create-investor-account
  .form-normal
  form
  .field-group-item
  .type-option-normal
  ul {
  width: 100%;
  display: table;
  max-width: 480px;
}
.page-create-investor-account
  .form-normal
  form
  .field-group-item
  .type-option-normal
  ul
  li {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border: 1px solid #006aff;
  border-right: none;
  height: 48px;
  min-width: 25%;
  color: #006aff;
  font-size: 14px;
}
.page-create-investor-account
  .form-normal
  form
  .field-group-item
  .type-option-normal
  ul
  li.active {
  background: #006aff;
  color: #ffffff;
}
.page-create-investor-account
  .form-normal
  form
  .field-group-item
  .type-option-normal
  ul
  li:hover {
  cursor: pointer;
}
.page-create-investor-account
  .form-normal
  form
  .field-group-item
  .type-option-normal
  ul
  li:last-child {
  border-right: 1px solid #006aff;
}
.page-create-investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul {
  display: table;
}
.page-create-investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li {
  margin-right: 32px;
  border: 1px solid #006aff;
  min-width: 168px;
  display: inline-block;
  vertical-align: middle;
}
.page-create-investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li.active {
  background: #006aff;
  color: #ffffff;
}
.page-create-investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li
  .icon {
  display: inline-block;
}
.page-create-investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li
  .text-option {
  display: inline-block;
}
.page-create-investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li
  .text-option
  span {
  display: block;
}

/* create investor account
  -----------------------------------------------------*/
.page-create-account.investor-account .form-normal form .field-group-item {
  margin-bottom: 33px;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .hl-title {
  font-weight: 500;
  font-size: 16px;
  color: #000;
  line-height: 1.5;
  margin-bottom: 15px;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .radio-option {
  position: relative;
  margin: -2px 0px 0px;
  padding: 0px 0px 2px;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-normal {
  padding-bottom: 6px;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-normal
  ul {
  width: 100%;
  display: table;
  max-width: 480px;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-normal
  ul
  li {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border: 1px solid #006aff;
  border-right: none;
  height: 48px;
  width: 25%;
  color: #006aff;
  font-size: 14px;
  font-weight: 600;
}
@media screen and (max-width: 575px) {
  .page-create-account.investor-account
    .form-normal
    form
    .field-group-item
    .type-option-normal
    ul
    li {
    line-height: 1.2;
    padding: 0px 5px;
  }
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-normal
  ul
  li.active {
  background: #006aff;
  color: #ffffff;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-normal
  ul
  li:hover {
  cursor: pointer;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-normal
  ul
  li:last-child {
  border-right: 1px solid #006aff;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg {
  padding-bottom: 6px;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul {
  width: 100%;
  display: table;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li {
  cursor: pointer;
  min-width: 168px;
  margin-right: 12px;
  border: 1px solid #006aff;
  min-width: 168px;
  display: inline-block;
  vertical-align: middle;
  padding: 0px 24px;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li:last-child {
  margin-right: 0px;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li.active {
  background: #006aff;
  color: #ffffff;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li.active
  .item-option
  .inner
  .text-option
  span {
  color: #ffffff;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li
  .item-option {
  display: table;
  height: 70px;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li
  .item-option
  .inner {
  display: table-cell;
  vertical-align: middle;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li
  .item-option
  .inner
  .icon {
  display: inline-block;
  display: table-cell;
  vertical-align: middle;
  padding-right: 17px;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li
  .item-option
  .inner
  .text-option {
  display: table-cell;
  vertical-align: middle;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li
  .item-option
  .inner
  .text-option
  span {
  display: block;
  line-height: 1.48;
  color: #004466;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .type-option-lg
  ul
  li
  .item-option
  .inner
  .text-option
  span:first-child {
  font-weight: 600;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item
  .bootstrap-select {
  padding-bottom: 6px;
}
.page-create-account.investor-account
  .form-normal
  form
  .field-group-item.field-radio-option {
  margin-bottom: 31px;
}
@media screen and (min-width: 992px) {
  .page-create-account.investor-account.investor-account-01 {
    padding: 21px 0px 120px 0px;
  }
}
@media screen and (min-width: 768px) {
  .page-create-account.investor-account .row.is-button-group {
    padding-top: 17px;
    margin: 0px -12px 0px -12px;
  }
}
@media screen and (max-width: 767px) {
  .page-create-account.investor-account
    .form-normal
    form
    .field-group-item
    .type-option-lg
    ul
    li {
    width: 100%;
    margin-bottom: 20px;
  }
  .page-create-account.investor-account
    .form-normal
    form
    .field-group-item
    .type-option-lg
    ul
    li:last-child {
    margin-bottom: 0px;
  }
  .page-create-account.investor-account .form-normal form .button-group {
    max-width: 100%;
  }
}

/* create brocker account
  -----------------------------------------------------*/
/* login
  -----------------------------------------------------*/
.page-login {
  padding: 20px 0px 160px;
}
.page-login .form-login {
  max-width: 432px;
  margin: 0 auto;
}
.page-login .form-login form {
  padding: 36px 48px 39px 48px;
}
.page-login .form-login form .field.hl-margin {
  margin-bottom: 25px;
}
.page-login .form-login form .field .text {
  margin-top: 7px;
}
.page-login .form-login form .field .text p a {
  font-size: 14px;
}
.page-login .form-login form .text-bottom p {
  color: #002233;
  font-size: 14px;
  text-align: center;
  padding: 0px 0px 1px;
  line-height: 1.43;
}
.page-login .form-login form .button-group {
  margin: 0px 0px 17px;
}
@media screen and (max-width: 767px) {
  .page-login {
    padding: 30px 0px 50px;
  }
  .page-login .form-login form {
    padding: 36px 20px 45px 20px;
  }
}

/* forgot password
  -----------------------------------------------------*/
.page-forgot-password .form-forgot-password {
  max-width: 432px;
  margin: 0 auto;
}
.page-forgot-password .form-forgot-password form {
  padding: 41px 48px 40px 48px;
}
.page-forgot-password .form-forgot-password form .field .text {
  padding: 17px 0px 5px;
  color: #6e8b9a;
  font-size: 14px;
  line-height: 1.43;
}
.page-forgot-password .form-forgot-password form .button-group {
  margin-bottom: 18px;
}
.page-forgot-password .form-forgot-password form .text-bottom {
  text-align: center;
}
.page-forgot-password .form-forgot-password form .text-bottom p {
  font-size: 14px;
}
@media screen and (max-width: 767px) {
  .page-forgot-password .form-forgot-password form {
    padding: 35px 20px 40px 20px;
  }
}
@media screen and (min-width: 992px) {
  .page-forgot-password {
    padding: 24px 0px 284px;
  }
}

/* resert password
  -----------------------------------------------------*/
.page-reset-password .form-reset-password {
  max-width: 432px;
  margin: 0 auto;
}
.page-reset-password .form-reset-password form {
  padding: 36px 48px 45px 48px;
}
.page-reset-password .form-reset-password form .hl-margin {
  margin-bottom: 32px;
}
@media screen and (max-width: 767px) {
  .page-reset-password .form-reset-password form {
    padding: 36px 20px 45px 20px;
  }
}
@media screen and (min-width: 992px) {
  .page-reset-password {
    padding: 24px 0px 280px;
  }
}

/* deals
  -----------------------------------------------------*/
.triangle {
  position: relative;
  margin: 3em;
  padding: 1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #bada55;
  -webkit-box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
}

.triangle::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -0.5em;
  bottom: -2em;
  left: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1em solid black;
  border-color: transparent transparent #bada55 #bada55;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.4);
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.4);
}

.triangle--problem {
  position: relative;
  margin: 5em;
  padding: 1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #bada55;
  -webkit-box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
}

.triangle--problem::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -0.75em;
  bottom: -3em;
  left: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1.5em solid black;
  border-color: #bada55 transparent transparent transparent;
  -webkit-box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
  box-shadow: 0px 3px 3px 0 rgba(0, 0, 0, 0.4);
}

.smd-popup {
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  width: 177px;
  padding: 8px 12px 8px 12px;
  background: #ffffff;
  text-align: left;
  border-radius: 4px;
  z-index: 99;
}
.smd-popup ul {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.smd-popup ul:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -7px;
  right: -5px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 6px solid black;
  border-color: transparent transparent #fff #fff;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: rotate(-225deg);
  -ms-transform: rotate(-225deg);
  -o-transform: rotate(-225deg);
  transform: rotate(-225deg);
  -webkit-box-shadow: -2px 2px 2px #f2f3f4;
  box-shadow: -2px 2px 2px #f2f3f4;
}
.smd-popup ul li a {
  line-height: 1.43;
  font-size: 14px;
  color: #004466;
  margin-bottom: 8px;
  display: block;
}
.smd-popup ul li a:hover {
  color: #006aff;
}
.smd-popup ul li:last-child a {
  margin-bottom: 0px;
}

.page-all-deals {
  padding: 32px 0px 0px;
}
.page-all-deals .title {
  padding: 0px 0px 15px;
}
.page-all-deals .title h1 {
  margin: 0px 0px 8px;
  font-size: 32px;
  line-height: 1.5;
  font-weight: 300;
}
.page-all-deals .md-seach-filter {
  margin: 0px 0px 18px;
}
@media screen and (min-width: 768px) {
  .page-all-deals .title .btn-plus {
    float: right;
  }
}
@media screen and (max-width: 767px) {
  .page-all-deals .title {
    padding: 0px 0px 20px;
  }
}

.md-tabs .tab-nav {
  margin: 0px 0px 24px;
}
.md-tabs .tab-nav .nav {
  display: block;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  position: relative;
  padding: 0px 0px 2px;
}
.md-tabs .tab-nav .nav:after {
  content: "";
  width: 100%;
  height: 2px;
  display: block;
  background: #ecf3f7;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.md-tabs .tab-nav .nav-item {
  display: inline-block;
  white-space: normal;
  margin: 0px 28px 0px 0px;
}
.md-tabs .tab-nav .nav-item a {
  color: #004466;
  position: relative;
  display: block;
  padding: 0px 0px 7px;
  font-weight: 500;
}
.md-tabs .tab-nav .nav-item a.active {
  color: #006aff;
}
.md-tabs .tab-nav .nav-item a.active:after {
  content: "";
  width: -webkit-calc(100% + 1px);
  width: calc(100% + 1px);
  height: 4px;
  display: block;
  background: #006aff;
  position: absolute;
  bottom: -2px;
  left: 0px;
  z-index: 50;
}

.md-seach-filter .search .is-search {
  position: relative;
  float: left;
  width: 280px;
  margin: 0px 24px 0px 0px;
}
.md-seach-filter .search .is-search .search-filter {
  padding: 14px 9px 12px 9px;
  width: 280px;
  -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
  border-radius: 2px;
  position: absolute;
  top: -webkit-calc(100% + 7px);
  top: calc(100% + 7px);
  background: #ffffff;
  z-index: 99;
}
.md-seach-filter .search .is-search .search-filter ul li {
  margin-bottom: 10px;
  color: #004466;
  line-height: 1.38;
  font-size: 16px;
}
.md-seach-filter .search .is-search .search-filter ul li:last-child {
  margin-bottom: 0px;
}
.md-seach-filter .search .is-search.active .frm-search input[type="text"] {
  border: solid 1px #006aff;
}
.md-seach-filter .search .filter {
  float: left;
}
.md-seach-filter .search .form-control {
  padding-right: 40px;
  width: 100%;
}
.md-seach-filter .search .btn-search {
  position: absolute;
  background: url(../images/ico-search-gray.png) no-repeat center top 14px;
  width: 42px;
  height: 48px;
  right: 0px;
  top: 0px;
  border: none;
  cursor: pointer;
  padding: 0;
}
@media screen and (max-width: 480px) {
  .md-seach-filter .search .is-search {
    width: -webkit-calc(100% - 124px);
    width: calc(100% - 124px);
  }
  .md-seach-filter .search .filter {
    width: 100px;
  }
  .md-seach-filter .search .filter .btn {
    width: 100%;
  }
}

.md-table-normal .is-checkbox .ui.checkbox label {
  padding-left: 20px;
}
.md-table-normal .table {
  margin: 0;
}
.md-table-normal .table thead tr td {
  font-size: 14px;
  line-height: 1.43;
  color: #6e8b9a;
  text-align: right;
  border-bottom: 1px solid #ebeeef;
  border-top: none;
  cursor: pointer;
  padding-top: 8px;
  padding-bottom: 8px;
}
.md-table-normal .table thead tr td .tbl-title {
  float: right;
}
.md-table-normal .table thead tr td span {
  display: block;
  float: left;
}
.md-table-normal .table thead tr td .ico {
  width: 8px;
  height: 6px;
  background: url(../images/ico-arrow-down-01.png) no-repeat;
  display: block;
  margin: 7px 0px 0px 7px;
}
.md-table-normal .table thead tr td.company {
  min-width: 195px;
}
.md-table-normal .table thead tr td.market {
  min-width: 170px;
}
.md-table-normal .table thead tr td.last-price {
  min-width: 105px;
}
.md-table-normal .table thead tr td.raise-price {
  min-width: 150px;
}
.md-table-normal .table thead tr td.captipal-raise {
  min-width: 135px;
}
.md-table-normal .table thead tr td.discount {
  min-width: 115px;
}
.md-table-normal .table thead tr td.bids-due {
  min-width: 115px;
}
.md-table-normal .table thead tr td.settlement-date {
  min-width: 150px;
}
.md-table-normal .table thead tr td.copy {
  min-width: 44px;
}
.md-table-normal .table thead tr td.bid-now {
  min-width: 95px;
  text-align: center;
}
.md-table-normal .table thead tr td.arrow {
  min-width: 46px;
}
.md-table-normal .table thead tr td.actions {
  min-width: 37px;
}
.md-table-normal .table tbody tr td {
  font-weight: 500;
  line-height: 1.4;
  color: #002233;
  text-align: right;
  font-size: 16px;
  border-bottom: 1px solid #ebeeef;
  border-top: none;
  vertical-align: middle;
  padding-top: 21px;
  padding-bottom: 17px;
  position: relative;
}
.md-table-normal .table tbody tr td span {
  display: block;
  font-weight: 300;
  font-size: 14px;
  color: #6e8b9a;
}
.md-table-normal .table tbody tr td .company-detail {
  display: table;
}
.md-table-normal .table tbody tr td .is-checkbox {
  padding: 0px 0px 0px 7px;
  display: table-cell;
  vertical-align: middle;
}
.md-table-normal .table tbody tr td .is-checkbox .ui.checkbox {
  display: block;
  height: 20px;
}
.md-table-normal .table tbody tr td .company-logo {
  padding: 0px 0px 0px 18px;
  display: table-cell;
  vertical-align: middle;
}
.md-table-normal .table tbody tr td .company-logo img {
  max-width: 32px;
}
.md-table-normal .table tbody tr td .company-name {
  padding: 0px 0px 0px 8px;
  display: table-cell;
  vertical-align: middle;
}
.md-table-normal .table tbody tr td .company-name span {
  font-size: 16px;
  font-weight: 500;
  color: #006aff;
}
.md-table-normal .table tbody tr td .copy {
  width: 16px;
  height: 20px;
  display: block;
  background: url(../images/ico-copy.png) no-repeat;
  margin: 0px 0px 0px;
}
.md-table-normal .table tbody tr td .arrow {
  width: 12px;
  height: 8px;
  display: block;
  background: url(../images/ico-arrow-down.png) no-repeat;
  margin: 0px 0px 0px;
  cursor: pointer;
}
.md-table-normal .table tbody tr td .bid-now {
  width: 40px;
  height: 40px;
  display: block;
  background: url(../images/ico-arrow-next-01.png) no-repeat center center;
  margin: 0px auto;
  cursor: pointer;
  border: 1px solid #006aff;
  border-radius: 50%;
}
.md-table-normal .table tbody tr td .bid-now:hover {
  background: url(../images/ico-arrow-next-02.png) no-repeat center center
    #6a97ff;
  border: 1px solid #6a97ff;
}
.md-table-normal .table tbody tr td .actions {
  width: 4px;
  height: 16px;
  display: block;
  background: url(../images/ico-action-01.png) no-repeat;
  margin: 0px 0px 0px;
  cursor: pointer;
}
.md-table-normal .table tbody tr td .smd-popup {
  position: absolute;
  right: 0px;
  top: -webkit-calc(100% - 11px);
  top: calc(100% - 11px);
}
.md-table-normal .table tbody tr td.no-wrap {
  white-space: nowrap;
}
.md-table-normal .table tbody tr td .timeline {
  background: url(../images/dashed-timeline.png) repeat-y left 130px top 2px;
  margin: 7px 5px 33px;
  padding: 0px 0px 10px;
}
.md-table-normal .table tbody tr td .timeline .item {
  margin: 0px 0px 17px 0px;
  display: table;
}
.md-table-normal .table tbody tr td .timeline .item .date-time {
  display: table-cell;
  vertical-align: middle;
  padding: 0px 0px 0px 0px;
}
.md-table-normal .table tbody tr td .timeline .item .date-time span {
  color: #6e8b9a;
  font-size: 14px;
  line-height: 1.38;
}
.md-table-normal .table tbody tr td .timeline .item .date-time span.date {
  font-size: 16px;
}
.md-table-normal .table tbody tr td .timeline .item .action {
  display: table-cell;
  vertical-align: middle;
  padding: 0px 0px 0px 73px;
  position: relative;
}
.md-table-normal .table tbody tr td .timeline .item .action span {
  color: #002233;
  font-size: 16px;
  line-height: 1.38;
}
.md-table-normal .table tbody tr td .timeline .item .action:before {
  content: "";
  width: 7px;
  height: 7px;
  display: block;
  background: #006aff;
  border-radius: 50%;
  position: absolute;
  left: 32px;
  top: 17px;
}
.md-table-normal .table tbody tr.active {
  background: #ecf3f7;
}
.md-table-normal .table tbody tr.active td .arrow {
  background: url(../images/ico-arrow-up.png) no-repeat;
}
.md-table-normal .table tbody tr.active + .hidden {
  display: table-row;
}
.md-table-normal .table tbody tr.hidden {
  background: #ecf3f7;
  display: none;
}
.md-table-normal .table tbody tr.hidden td {
  text-align: left;
}
.md-table-normal .table tbody tr:hover td {
  background: #ecf3f7;
}
@media screen and (max-width: 1214px) {
  .md-table-normal {
    overflow-x: scroll;
  }
}
@media screen and (min-width: 1215px) {
  .md-table-normal .table tbody tr td .text {
    padding: 0px 15px 0px 0px;
  }
}

/* add new deals  
  -----------------------------------------------------*/
.md-nav-sidebar {
  position: absolute;
  right: 0px;
  top: 72px;
  background: #fff;
  width: 320px;
  -webkit-box-shadow: -2px 0 6px 0 rgba(0, 34, 51, 0.1);
  box-shadow: -2px 0 6px 0 rgba(0, 34, 51, 0.1);
  z-index: 999;
}
.md-nav-sidebar .ctn-inner {
  position: relative;
  height: 100%;
}
.md-nav-sidebar .nav-title {
  position: relative;
  padding: 17px 17px 15px 17px;
  border-bottom: 1px solid #ecf3f7;
  margin: 0px 0px 0px;
}
.md-nav-sidebar .nav-title h3 {
  margin: 0px 0px 0px;
  font-size: 16px;
  line-height: 1.5;
}
.md-nav-sidebar .nav-title .btn-close {
  width: 14px;
  height: 14px;
  display: block;
  background: url(../images/ico-close.png) no-repeat;
  position: absolute;
  top: 21px;
  right: 21px;
  cursor: pointer;
}
.md-nav-sidebar .nav-content {
  padding: 24px 20px 0px;
  margin: 0px 0px 30px;
}
.md-nav-sidebar .nav-content .avatar {
  margin: 0px auto 14px;
  position: relative;
  width: 72px;
  height: 72px;
}
.md-nav-sidebar .nav-content .avatar img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}
.md-nav-sidebar .nav-content .avatar .upload-picture {
  position: absolute;
  bottom: -13px;
  right: -7px;
}
.md-nav-sidebar .nav-content .avatar .upload-picture .btn-upload {
  background: url(../images/ico-upload-picture.png) no-repeat center center #fff;
  width: 32px;
  height: 32px;
  display: block;
  cursor: pointer;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 34, 51, 0.1);
  box-shadow: 0 0 4px 0 rgba(0, 34, 51, 0.1);
}
.md-nav-sidebar .nav-content .avatar .upload-picture .input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.md-nav-sidebar .nav-content .information {
  border-bottom: 1px solid #ecf3f7;
  text-align: center;
  padding: 0px 0px 16px;
  margin: 0px 0px 33px;
}
.md-nav-sidebar .nav-content .information p span {
  display: block;
}
.md-nav-sidebar .nav-content .information p span.name {
  font-weight: 600;
  line-height: 1.5;
  color: #002233;
  font-size: 20px;
}
.md-nav-sidebar .nav-content .information p span.email {
  font-size: 14px;
  line-height: 1.43;
  color: #004466;
}
.md-nav-sidebar .nav-content .menu {
  margin: 0px 0px 24px;
}
.md-nav-sidebar .nav-content .menu ul li {
  line-height: 1.38;
  margin: 0px 0px 24px;
}
.md-nav-sidebar .nav-content .menu ul li:last-child {
  margin: 0px 0px 0px;
}
.md-nav-sidebar .nav-content .all-notification {
  margin-bottom: 154px;
}
.md-nav-sidebar .nav-content .all-notification ul li {
  padding: 0px 16px 0px 16px;
}
.md-nav-sidebar .nav-content .all-notification ul li .inner {
  border-bottom: 1px solid #ecf3f7;
  padding: 7px 0px 15px 0px;
}
.md-nav-sidebar .nav-content .all-notification ul li:hover {
  background: #fafbfc;
  cursor: pointer;
}
.md-nav-sidebar .nav-content .all-notification ul li.new .meta .name {
  padding-left: 16px;
  position: relative;
}
.md-nav-sidebar .nav-content .all-notification ul li.new .meta .name:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: block;
  background: #006aff;
  top: 7px;
  left: 0px;
}
.md-nav-sidebar .nav-content .all-notification ul li.unread {
  background: #fafbfc;
}
.md-nav-sidebar .nav-content .all-notification ul li .meta {
  margin-bottom: 1px;
}
.md-nav-sidebar .nav-content .all-notification ul li .meta .name {
  font-size: 16px;
  color: #002233;
  font-weight: 600;
  line-height: 1.5;
}
.md-nav-sidebar .nav-content .all-notification ul li .meta .time {
  float: right;
  font-size: 14px;
  line-height: 1.43;
  color: #6e8b9a;
  margin: 4px 0px 0px;
}
.md-nav-sidebar
  .nav-content
  .all-notification
  ul
  li
  .content-notification
  .title {
  color: #002233;
  line-height: 1.43;
  font-size: 14px;
  margin-bottom: 0px;
  padding: 0px;
}
.md-nav-sidebar
  .nav-content
  .all-notification
  ul
  li
  .content-notification
  .excerpt
  p {
  color: #6e8b9a;
  line-height: 1.43;
  font-size: 14px;
  margin-bottom: 0px;
}
.md-nav-sidebar .nav-bottom {
  border-top: 1px solid #ecf3f7;
  padding: 15px 17px 15px 17px;
  text-align: center;
}
.md-nav-sidebar .nav-bottom a {
  padding: 2px 0px 2px 27px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: #6e8b9a;
}
.md-nav-sidebar .nav-bottom.logout a {
  background: url(../images/ico-logout.png) no-repeat left top 1px;
}
.md-nav-sidebar.nav-notification .nav-content {
  padding: 0px 0px 0px 0px;
}

.page-deals {
  padding: 33px 0px 66px;
}
.page-deals h1 {
  font-size: 32px;
  line-height: 1.5;
  color: #002233;
  font-weight: 300;
  margin-bottom: 24px;
}
.page-deals .form-normal form .col-sm-12 .field-input.is-field-upload {
  padding: 34px 20px 30px 20px;
}

.page-add-new-deal .form-normal form {
  padding: 36px 48px 56px 48px;
}
.page-add-new-deal .form-normal form .button-group {
  margin: 21px 0px 0px;
}
.page-add-new-deal .form-normal form .field-input.is-field-upload {
  padding: 47px 20px 46px 20px;
}
@media screen and (min-width: 992px) {
  .page-add-new-deal .form-normal {
    max-width: 800px;
  }
}

/* deal add doc
  -----------------------------------------------------*/
.md-top-deal {
  background: #ffffff;
  padding: 48px 0px 40px;
}
.md-top-deal .ctn-inner {
  position: relative;
}
.md-top-deal .logo {
  width: 176px;
  height: 176px;
  border-radius: 4px;
  border: solid 1px #f2f2f2;
  display: table;
  text-align: center;
}
.md-top-deal .logo .inner {
  display: table-cell;
  vertical-align: middle;
}
.md-top-deal .information {
  /* display: table; */
  height: 100%;
  padding: 12px 0px 0px;
}
.md-top-deal .information .inner-infomation {
  display: table-cell;
  vertical-align: middle;
}
.md-top-deal .information h1 {
  font-size: 32px;
  margin-bottom: 5px;
}
.md-top-deal .information .sub-title {
  color: #006aff;
  margin-bottom: 16px;
  line-height: 1.38;
}
.md-top-deal .information .detail .price {
  color: #002233;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.5em;
  margin-bottom: 2px;
}
.md-top-deal .information .detail .number {
  color: #002233;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.5em;
  margin-bottom: 2px;
}
.md-top-deal .information .detail .text {
  color: #6e8b9a;
  font-size: 14px;
  line-height: 1.4;
}
@media screen and (min-width: 1215px) {
  /*.md-top-deal .deal-inner {*/
  /*  max-width: 799px;*/
  /*}*/
  .md-top-deal .information .detail .col-sm-4 .inner {
    max-width: 220px;
  }
  .md-top-deal .information .detail .col-sm-4:nth-child(2) {
    margin: 0px 0px 0px 57px;
  }
}
@media screen and (max-width: 767px) {
  .md-top-deal .logo {
    margin-bottom: 20px;
  }
  .md-top-deal .information h1 {
    margin-bottom: 35px;
    font-size: 25px;
  }
}

.md-tabs.tab-deal-add-doc {
  padding: 47px 0px 20px;
}
.md-tabs.tab-deal-add-doc .tab-company-overview {
  margin: 0px 0px 45px;
}
.md-tabs.tab-deal-add-doc .tab-company-overview .tab-text {
  margin-bottom: 32px;
}
.md-tabs.tab-deal-add-doc .tab-company-overview .tab-text p {
  color: #004466;
  font-size: 16px;
  line-height: 1.38;
}
.md-tabs.tab-deal-add-doc .tab-company-overview .tab-detail {
  margin-bottom: 30px;
  line-height: 1.38;
}
.md-tabs.tab-deal-add-doc .tab-company-overview .tab-detail ul li {
  margin-bottom: 15px;
}
.md-tabs.tab-deal-add-doc .tab-company-overview .tab-detail .dd-title p {
  color: #6e8b9a;
}
.md-tabs.tab-deal-add-doc .tab-company-overview .tab-detail .dt-detail p {
  color: #002233;
  line-height: 22px;
}
.md-tabs.tab-deal-add-doc .tab-company-overview .tab-tags ul li {
  border-radius: 16px;
  display: inline-block;
  background: #ecf3f7;
  padding: 5px 12px 3px;
  margin: 0px 15px 15px 0px;
  line-height: 1.38;
}
.md-tabs.tab-deal-add-doc .tab-company-overview .tab-tags ul li a {
  color: #004466;
}
.md-tabs.tab-deal-add-doc .tab-company-overview .tab-tags ul li:last-child {
  margin-right: 0px;
}
.md-tabs.tab-light {
  padding: 9px 0px 0px;
}
.md-tabs.tab-light .tab-nav .nav-item a.active:after {
  height: 2px;
}
.md-tabs.tab-light .tab-nav {
  margin: 0px 0px 28px;
}
.md-tabs.tab-light .tab-nav ul {
  overflow-x: hidden;
}
.md-tabs.tab-light .tab-nav ul li {
  float: left;
  width: 50%;
  text-align: center;
  margin: 0px 0px 0px;
}
.md-tabs.tab-light .tab-nav ul li a {
  padding: 0px 0px 4px;
}
.md-tabs.tab-light .tab-nav ul li a.active {
  color: #004466;
}
.md-tabs.tab-light .tab-content .form-normal form .field {
  margin-bottom: 20px;
}
.md-tabs.tab-light .tab-content .form-normal form .button-group {
  padding-top: 12px;
}
@media screen and (min-width: 1215px) {
  .md-tabs.tab-deal-add-doc .tab-inner {
    max-width: 799px;
  }
}

.md-deal-form h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  color: #002233;
  margin: 0px 0px 9px;
}
.md-deal-form .form-normal {
  -webkit-box-shadow: 0 8px 28px 0 rgba(0, 0, 0, 0.07);
  box-shadow: 0 8px 28px 0 rgba(0, 0, 0, 0.07);
}
.md-deal-form .form-normal form {
  padding: 24px 24px 25px 24px;
}
.md-deal-form .form-normal form .field {
  margin: 0px 0px 20px;
}
.md-deal-form .form-normal form .field .form-control-textarea {
  height: 88px;
}
.md-deal-form .form-normal form .field.field-upload {
  margin: 0px 0px 13px;
}
.md-deal-form .form-normal form .field.field-file-detail {
  margin-bottom: 33px;
}
.md-deal-form .form-normal form .button-group {
  margin: 0px 0px 18px;
}
.md-deal-form .form-normal form .button-group.hl-button-group {
  padding: 15px 0px 0px;
}
.md-deal-form .form-normal form .text-bottom {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
}
.md-deal-form .form-normal form .field .smd-file-detail {
  padding: 12px 0px 0px;
}
.md-deal-form .form-normal .excerpt {
  font-size: 14px;
  line-height: 1.43;
  margin: 0px 0px 20px;
  color: #6e8b9a;
}

.page-deal-add-doc {
  padding: 0px 0px 0px;
}
.page-deal-add-doc .tab-deal-add-doc {
  margin: 0px 0px 50px;
}
.page-deal-add-doc .tab-deal-add-doc .tab-nav {
  margin: 0px 0px 25px;
}
/*@media screen and (min-width: 1215px) {*/
/*  .page-deal-add-doc .md-deal-form {*/
/*    position: absolute;*/
/*    right: auto;*/
/*    top: 120px;*/
/*    left: 960px;*/
/*    width: 384px;*/
/*    z-index: 50;*/
/*  }*/
/*}*/

/* deal directors 
  -----------------------------------------------------*/
.md-tabs.tab-deal-add-doc .tab-directors {
  margin: -2px 0px 60px;
}
.md-tabs.tab-deal-add-doc .tab-directors .item {
  background: #fff;
  border-radius: 4px;
  padding: 23px 32px 22px 32px;
  border: 1px solid #f4f5f6;
}
.md-tabs.tab-deal-add-doc .tab-directors .item .avatar {
  float: left;
  margin: 0px 16px 0px 0px;
  overflow: hidden;
}
.md-tabs.tab-deal-add-doc .tab-directors .item .avatar img {
  border-radius: 2px;
  width: 88px;
}
.md-tabs.tab-deal-add-doc .tab-directors .item .information {
  float: left;
  width: -webkit-calc(100% - 104px);
  width: calc(100% - 104px);
}
.md-tabs.tab-deal-add-doc .tab-directors .item .information h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  color: #002233;
  margin: 0px 0px 1px;
}
.md-tabs.tab-deal-add-doc .tab-directors .item .information .sub-title {
  margin: 0px 0px 10px;
  font-size: 16px;
  line-height: 22px;
  color: #006aff;
}
.md-tabs.tab-deal-add-doc .tab-directors .item .smd-social ul li {
  margin-right: 9px;
}
.md-tabs.tab-deal-add-doc .tab-directors .item .smd-social ul li a {
  display: block;
  width: 100%;
}
.md-tabs.tab-deal-add-doc .tab-directors .item .smd-social ul li a img {
  width: 19px;
  height: auto;
}
@media screen and (max-width: 576px) {
  .md-tabs.tab-deal-add-doc .tab-directors .col-sm-6 {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 400px) {
  .md-tabs.tab-deal-add-doc .tab-directors .item .avatar {
    margin-right: 13px;
  }
  .md-tabs.tab-deal-add-doc .tab-directors .item .information h3 {
    font-size: 17px;
  }
  .md-tabs.tab-deal-add-doc .tab-directors .item .information .sub-title {
    font-size: 14px;
  }
}
@media screen and (max-width: 350px) {
  .md-tabs.tab-deal-add-doc .tab-directors .item .avatar {
    width: 75px;
    margin-right: 10px;
  }
  .md-tabs.tab-deal-add-doc .tab-directors .item .information h3 {
    font-size: 15px;
  }
  .md-tabs.tab-deal-add-doc .tab-directors .item .information .sub-title {
    font-size: 13px;
    margin-bottom: 4px;
  }
}

/* deal page quarterly cash flows 
  -----------------------------------------------------*/
.md-tabs.tab-deal-add-doc .tab-cash-flows {
  margin: 0px 0px 54px;
}
.md-tabs.tab-deal-add-doc .tab-cash-flows .item p {
  margin-bottom: 16px;
}
.md-tabs.tab-deal-add-doc .tab-cash-flows .item p .dd-title {
  min-width: 209px;
  display: block;
  font-size: 16px;
  line-height: 1.38;
  color: #6e8b9a;
  float: left;
}
.md-tabs.tab-deal-add-doc .tab-cash-flows .item p .dt-detail {
  width: -webkit-calc 100% -209px;
  width: calc 100% -209px;
  float: left;
  color: #002233;
  font-size: 16px;
  line-height: 1.38;
}
@media screen and (max-width: 575px) {
  .md-tabs.tab-deal-add-doc .tab-cash-flows .item p .dd-title {
    min-width: 150px;
  }
  .md-tabs.tab-deal-add-doc .tab-cash-flows .item p .dt-detail {
    width: -webkit-calc(100% - 150px);
    width: calc(100% - 150px);
  }
}

/* deal page reviews
  -----------------------------------------------------*/
.smd-frm-rating {
  float: right;
  display: table;
}
.smd-frm-rating .title {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
}
.smd-frm-rating span {
  display: block;
  margin: 0px 18px 0px 0px;
  font-size: 14px;
  line-height: 20px;
  color: #6e8b9a;
}
.smd-frm-rating .rate-stars {
  display: table-cell;
  vertical-align: middle;
  float: left;
}
.smd-frm-rating .rate-stars ul {
  margin: 0px 0px 0px -5px;
}
.smd-frm-rating .rate-stars ul li {
  float: left;
  margin: 0px 0px 0px 5px;
}
@media screen and (max-width: 992px) {
  .smd-frm-rating span {
    margin: 0px 10px 0px 0px;
    line-height: 1em;
  }
  .smd-frm-rating .title {
    display: block;
    text-align: right;
  }
  .smd-frm-rating .title span {
    margin-right: 0px;
  }
  .smd-frm-rating .rate-stars {
    display: block;
    float: right;
  }
}

.smd-progress-feedback .item .is-checkbox {
  display: inline-block;
  overflow: hidden;
  float: left;
  width: 116px;
}
.smd-progress-feedback .item .is-checkbox label {
  margin-bottom: 6px;
}
.smd-progress-feedback .item .is-progress {
  width: -webkit-calc(100% - 116px);
  width: calc(100% - 116px);
  float: left;
}
.smd-progress-feedback .item .is-progress .progress {
  background: #e1e9ed;
  border-radius: 2px;
  height: 12px;
  width: -webkit-calc(100% - 52px);
  width: calc(100% - 52px);
  float: left;
}
.smd-progress-feedback .item .is-progress .percent {
  width: 52px;
  float: left;
  line-height: 1em;
  font-size: 14px;
  color: #002233;
  text-align: right;
}
.smd-progress-feedback .item .progress-bar {
  background: #004466;
  border-radius: 2px;
}

.bl-feedback-results .top-feedback h3 {
  margin-bottom: 18px;
  font-size: 20px;
  line-height: 30px;
  color: #002233;
  font-weight: 900;
  display: inline-block;
  float: left;
  margin-right: 8px;
}
.bl-feedback-results .top-feedback .number {
  float: left;
  margin-right: 42px;
  font-size: 20px;
  line-height: 30px;
  color: #6e8b9a;
}
.bl-feedback-results .smd-frm-rating {
  float: left;
}

.smd-user-information .avatar {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin-right: 17px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
}
.smd-user-information .avatar img {
  width: 100%;
}
.smd-user-information .information {
  display: inline-block;
  width: -webkit-calc(100% - 57px);
  width: calc(100% - 57px);
}
.smd-user-information .information .top-meta {
  margin-bottom: 2px;
}
.smd-user-information .information .top-meta .name {
  font-size: 16px;
  font-weight: 600;
  color: #002233;
  line-height: 22.4px;
}
.smd-user-information .information .top-meta .time {
  font-weight: 300;
  color: #6e8b9a;
  font-size: 14px;
  line-height: 22.4px;
  position: relative;
}
.smd-user-information .information .number-review {
  line-height: 20px;
  font-size: 14px;
  color: #6e8b9a;
}

.md-meta-comment {
  padding: 16px 0px 8px 0px;
}
.md-meta-comment .smd-frm-rating {
  margin-top: 8px;
}
@media screen and (max-width: 992px) {
  .md-meta-comment .smd-frm-rating {
    margin: -2px 0px 0px 56px;
  }
  .md-meta-comment .smd-frm-rating span {
    margin-top: 7px;
  }
  .md-meta-comment .smd-frm-rating .rate-stars {
    float: left;
  }
}

.bl-form-comment {
  padding: 0px 25px;
  margin-bottom: 16px;
  border-radius: 4px;
  background: #fff;
}
.bl-form-comment .frm-comment {
  padding-bottom: 19px;
}
.bl-form-comment .frm-comment form {
  border-bottom: 1px solid #f4f5f6;
  margin-bottom: 16px;
}
.bl-form-comment .frm-comment form textarea {
  padding: 17px 0px;
  font-size: 16px;
  line-height: 22px;
  height: 80px;
  background: #fff;
  color: #6e8b9a;
}
.bl-form-comment .smd-frm-rating {
  float: left;
}
@media screen and (min-width: 992px) {
  .bl-form-comment .smd-frm-rating {
    float: right;
  }
}
@media screen and (max-width: 767px) {
  .bl-form-comment {
    padding: 0px 10px;
  }
}
.bl-form-comment .md-meta-comment {
  border-bottom: 1px solid #f4f5f6;
}

.md-comment-actions .meta-actions {
  display: inline-block;
  float: left;
}
.md-comment-actions .meta-actions span {
  padding-left: 30px;
  position: relative;
  margin-right: 38px;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  color: #6e8b9a;
}
.md-comment-actions .meta-actions span:hover {
  color: #006aff;
}
.md-comment-actions .meta-actions span:last-child {
  margin-right: 0px;
}
.md-comment-actions .meta-actions span:before {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.md-comment-actions .meta-actions span.like:hover:before {
  background: url(../images/ico-like-01.png) no-repeat center center;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}
.md-comment-actions .meta-actions span.like:before {
  width: 20px;
  height: 20px;
  background: url(../images/ico-like-01.png) no-repeat center center;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}
.md-comment-actions .meta-actions span.share:hover:before {
  background: url(../images/ico-share-02.png) no-repeat center center;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}
.md-comment-actions .meta-actions span.share:before {
  width: 17px;
  height: 20px;
  background: url(../images/ico-share-01.png) no-repeat center center;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}
.md-comment-actions .meta-actions span.reply:hover:before {
  background: url(../images/ico-reply-01.png) no-repeat center center;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}
.md-comment-actions .meta-actions span.reply:before {
  width: 24px;
  height: 16px;
  background: url(../images/ico-reply-01.png) no-repeat center center;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
}
.md-comment-actions .view-more-reply {
  background: #fff;
  display: inline-block;
  float: right;
}
.md-comment-actions .view-more-reply.active a:before {
  background: url(../images/ico-arrow-up-02.png) no-repeat;
}
.md-comment-actions .view-more-reply a {
  display: block;
  position: relative;
  padding-right: 17px;
}
.md-comment-actions .view-more-reply a:before {
  content: "";
  width: 8px;
  height: 5px;
  display: block;
  background: url(../images/ico-arrow-down-02.png) no-repeat;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  right: 0px;
  top: 50%;
  position: absolute;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media screen and (max-width: 575px) {
  .md-comment-actions .meta-actions span {
    margin-right: 10px;
  }
}
@media screen and (max-width: 420px) {
  .md-comment-actions .meta-actions span {
    font-size: 14px;
    padding-left: 20px;
  }
  .md-comment-actions .meta-actions span.like:before {
    width: 15px;
  }
  .md-comment-actions .meta-actions span.share:before {
    width: 13px;
  }
  .md-comment-actions .meta-actions span.reply {
    padding-left: 22px;
  }
  .md-comment-actions .meta-actions span.reply:before {
    width: 18px;
  }
  .md-comment-actions .view-more-reply a {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .md-comment-actions .view-more-reply {
    padding: 12px 0px 0px;
    float: left;
  }
}

.bl-list-comment ul li {
  background: #fff;
  margin-bottom: 16px;
}
.bl-list-comment ul li .rate-stars ul {
  padding-top: 0px;
  border-top: none;
}
.bl-list-comment ul li .rate-stars ul li {
  border-left: none;
}
.bl-list-comment ul li ul {
  padding: 25px 0px 8px 0px;
  border-top: 1px solid #f4f5f6;
}
.bl-list-comment ul li ul li {
  margin: 0px 25px 24px 25px;
  border-left: 1px solid #006aff;
  border-bottom: none;
}
.bl-list-comment ul li ul li .md-meta-comment {
  padding: 0px 0px 12px 0px;
  border-bottom: none;
  margin: 0px 15px;
}
.bl-list-comment ul li ul li .content-comment {
  border-bottom: none;
  padding: 5px 0px 0px 0px;
}
.bl-list-comment .md-meta-comment {
  background: #fff;
  margin: 0px 25px 0px 25px;
  border-bottom: 1px solid #f4f5f6;
}
.bl-list-comment .content-comment {
  border-bottom: 1px solid #f4f5f6;
  padding: 17px 0px 15px 0px;
  margin: 0px 25px 0px 25px;
  background: #fff;
  line-height: 22px;
}
.bl-list-comment .content-comment p {
  color: #004466;
  font-size: 16px;
  line-height: 22px;
}
.bl-list-comment .md-comment-actions {
  padding: 16px 0px 12px 0px;
  margin: 0px 25px;
}
.bl-list-comment .smd-frm-rating {
  float: left;
}
@media screen and (min-width: 992px) {
  .bl-list-comment .smd-frm-rating {
    float: right;
  }
}

.md-tabs.tab-deal-add-doc .tab-reviews {
  margin: 0px 0px 60px;
}
.md-tabs.tab-deal-add-doc .tab-reviews .bl-feedback-results {
  margin-bottom: 13px;
}

/* page general infomation 
  -----------------------------------------------------*/
.page-deals.page-general-information {
  padding: 32px 0px 97px;
}
@media screen and (min-width: 992px) {
  .page-deals.page-general-information .form-create-account {
    max-width: 800px;
  }
}
.page-deals.page-general-information
  .form-normal
  form
  .field.field-file-detail {
  margin-bottom: 29px;
}
.page-deals.page-general-information .form-normal form .field .is-checkbox {
  padding: 6px 0px 0px;
}
.page-deals.page-general-information
  .form-normal
  form
  .field
  .is-checkbox
  .title {
  padding-bottom: 14px;
}

/* my advantages for deal 
  -----------------------------------------------------*/
.page-all-deals.page-create-account.investor-account {
  padding: 32px 0px 56px 0px;
}
.page-all-deals.page-create-account.investor-account .form-create-account {
  margin: 0;
}

/* landing page 
  -----------------------------------------------------*/
.md-steps .all-item .item {
  padding: 0px 0px 15px;
  position: relative;
}
.md-steps .all-item .item:last-child:before {
  display: none;
}
.md-steps .all-item .item:before {
  content: "";
  width: 2px;
  height: 100%;
  display: block;
  background: url(../images/bg-dotted.png) repeat-y top -6px center;
  position: absolute;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  top: 0px;
  left: 24px;
}
.md-steps .all-item .item .number {
  position: relative;
  z-index: 3;
  width: 52px;
  float: left;
  margin-right: 16px;
}
.md-steps .all-item .item .number span {
  font-weight: 500;
  background: #fff;
  width: 52px;
  height: 52px;
  display: block;
  border-radius: 50%;
  border: solid 2px #ebeeef;
  line-height: 52px;
  text-align: center;
  font-size: 20px;
  color: #006aff;
}
.md-steps .all-item .item .information {
  padding: 9px 0px 0px;
  display: inline-block;
  width: -webkit-calc(100% - 68px);
  width: calc(100% - 68px);
}
.md-steps .all-item .item .information .title {
  color: #002233;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.md-steps .all-item .item .information .text p {
  line-height: 1.43;
  font-size: 14px;
  color: #6e8b9a;
  max-width: 280px;
}

.bl-form-regitester {
  overflow: hidden;
  padding: 0px 0px 170px;
  position: relative;
  background: url(../images/bg-form-register.png) no-repeat right bottom 122px;
}
.bl-form-regitester .unlocked-market {
  margin: 0px 0px 36px;
}
.bl-form-regitester .unlocked-market h1 {
  font-weight: 500;
  line-height: 1.5;
  color: #002233;
  font-weight: 500;
  font-size: 40px;
}
.bl-form-regitester .unlocked-market .excerpt {
  padding: 3px 0px 0px;
  margin: 0px 0px 55px;
}
.bl-form-regitester .form-normal.form-register form {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0px 48px 33px 48px;
}
.bl-form-regitester .register-account {
  background: #fff;
  border-radius: 8px;
}
.bl-form-regitester .register-account h3 {
  font-size: 20px;
  font-weight: 600;
  color: #002233;
  line-height: 1.5;
  padding: 32px 48px 0px 48px;
  text-align: center;
}
@media screen and (min-width: 992px) {
  .bl-form-regitester .unlocked-market {
    max-width: 488px;
    padding: 21px 0px 0px;
  }
  .bl-form-regitester .register-account {
    max-width: 488px;
    float: right;
  }
}
@media screen and (max-width: 991px) {
  .bl-form-regitester {
    background: url(../images/bg-form-register.png) no-repeat right bottom 95px;
    padding: 0px 0px 130px;
    -webkit-background-size: 50% 50%;
    background-size: 50%;
  }
}
@media screen and (max-width: 767px) {
  .bl-form-regitester {
    -webkit-background-size: 90% 90%;
    background-size: 90%;
  }
}
@media screen and (max-width: 575px) {
  .bl-form-regitester .register-account h3 {
    padding: 32px 20px 0px 20px;
  }
  .bl-form-regitester .form-normal.form-register form {
    padding: 0px 20px 33px 20px;
  }
}

.md-timeline {
  position: relative;
  z-index: 1000;
}
.md-timeline .all-item {
  position: relative;
  padding: 30px 0px 0px;
}
.md-timeline .all-item .item {
  padding: 10px 24px 40px 24px;
}
.md-timeline .all-item .item .inner-item {
  position: relative;
}
.md-timeline .all-item .item .inner-item .number {
  position: absolute;
  top: -24px;
  left: -24px;
}
.md-timeline .all-item .item .inner-item .number span {
  line-height: 0.94;
  opacity: 0.05;
  color: #006aff;
  font-size: 64px;
  font-weight: 500;
}
.md-timeline .all-item .item .inner-item .information .title {
  color: #002233;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}
.md-timeline .all-item .item .inner-item .information .text p {
  font-size: 14px;
  color: #6e8b9a;
}
@media screen and (max-width: 767px) {
  .md-timeline .all-item .item .inner-item:before {
    display: none;
  }
  .md-timeline .all-item .item:last-child {
    padding: 10px 24px 20px 24px;
  }
}
@media screen and (min-width: 768px) {
  .md-timeline .all-item {
    padding: 25px 0px 0px;
  }
  .md-timeline .all-item:before {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    background: url(../images/bg-dotted-02.png) repeat-x;
    position: absolute;
    bottom: 1px;
    left: 0px;
  }
  .md-timeline .all-item .item {
    width: -webkit-calc(33.33% - 3px);
    width: calc(33.33% - 3px);
    text-align: center;
    display: inline-block;
    padding: 0px 0px 79px;
  }
  .md-timeline .all-item .item .inner-item {
    max-width: 280px;
    text-align: left;
    float: right;
  }
  .md-timeline .all-item .item .inner-item:before {
    content: "";
    display: block;
    width: 1px;
    height: 48px;
    background: url(../images/bg-dotted-01.png) no-repeat top center;
    position: absolute;
    left: 50%;
    bottom: -82%;
  }
  .md-timeline .all-item .item:nth-child(1) {
    float: left;
  }
  .md-timeline .all-item .item:nth-child(2) {
    padding: 9px 24px 0px 24px;
    position: absolute;
    left: 17.7%;
    top: 100%;
  }
  .md-timeline .all-item .item:nth-child(2) .inner-item {
    padding: 79px 0px 0px;
    top: 0px;
    bottom: auto !important;
  }
  .md-timeline .all-item .item:nth-child(2) .inner-item:before {
    top: -2px;
    bottom: auto;
  }
  .md-timeline .all-item .item:nth-child(2) .inner-item .number {
    top: 87px;
  }
  .md-timeline .all-item .item:nth-child(4) {
    padding: 9px 24px 0px 24px;
    position: absolute;
    left: 55%;
    top: 100%;
  }
  .md-timeline .all-item .item:nth-child(4) .inner-item {
    padding: 87px 0px 0px;
    top: 0px;
    bottom: auto;
  }
  .md-timeline .all-item .item:nth-child(4) .inner-item:before {
    top: -2px;
    bottom: auto;
  }
  .md-timeline .all-item .item:nth-child(4) .inner-item .number {
    top: 87px;
  }
  .md-timeline .all-item .item:nth-child(5) {
    float: right;
  }
}
@media screen and (min-width: 992px) {
  .md-timeline .all-item {
    padding: 71px 0px 0px;
  }
}

.bl-timeline {
  padding: 0px 0px 179px;
  background: #ffffff;
  position: relative;
}
.bl-timeline .inner-timeline {
  position: relative;
  z-index: 99;
}
.bl-timeline:before {
  content: "";
  display: block;
  background: #ffffff;
  height: 150px;
  position: absolute;
  width: 200%;
  left: -5%;
  top: -41px;
  z-index: 50;
  -webkit-transform: rotate(4.65deg);
  -ms-transform: rotate(4.65deg);
  -o-transform: rotate(4.65deg);
  transform: rotate(4.65deg);
}
.bl-timeline:after {
  content: "";
  display: block;
  background: #ffffff;
  height: 150px;
  position: absolute;
  width: 200%;
  left: -5%;
  bottom: -41px;
  z-index: 50;
  -webkit-transform: rotate(-4.65deg);
  -ms-transform: rotate(-4.65deg);
  -o-transform: rotate(-4.65deg);
  transform: rotate(-4.65deg);
}
.bl-timeline h2 {
  font-size: 32px;
  line-height: 1.5;
  color: #002233;
  margin: 0px 0px 23px;
}
@media screen and (max-width: 767px) {
  .bl-timeline {
    padding: 15px 0px 15px;
  }
}
@media screen and (max-width: 991px) {
  .bl-timeline:before {
    -webkit-transform: rotate(2.65deg);
    -ms-transform: rotate(2.65deg);
    -o-transform: rotate(2.65deg);
    transform: rotate(2.65deg);
    height: 80px;
  }
  .bl-timeline:after {
    height: 80px;
    -webkit-transform: rotate(-2.65deg);
    -ms-transform: rotate(-2.65deg);
    -o-transform: rotate(-2.65deg);
    transform: rotate(-2.65deg);
  }
}
@media screen and (max-width: 991px) {
  .bl-timeline:before {
    height: 70px;
  }
  .bl-timeline:after {
    height: 70px;
  }
}

.bl-capital-raises {
  padding: 234px 0px 80px;
}
.bl-capital-raises .information {
  text-align: center;
}
.bl-capital-raises .information h2 {
  font-size: 32px;
  line-height: 1.5;
  color: #002233;
  margin: 0px 0px 17px;
}
.bl-capital-raises .information .excerpt {
  max-width: 384px;
  margin: 0 auto 46px;
}
.bl-capital-raises .information .excerpt p {
  line-height: 1.38;
  color: #004466;
}
.bl-capital-raises .md-table-normal {
  background: #ffffff;
  border-radius: 8px;
  border: solid 1px #ecf3f7;
  -webkit-box-shadow: 0 52px 68px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 52px 68px 0 rgba(0, 0, 0, 0.04);
  margin: 0px 0px 20px;
}
@media screen and (max-width: 992px) {
  .bl-capital-raises {
    padding: 130px 0px 50px;
  }
}

@media screen and (min-width: 992px) {
  .home-page {
    padding: 24px 0px 0px;
  }
}

/* footer -  desktop view
  -----------------------------------------------------*/
.md-cookies {
  background: #ffffff;
  padding: 24px 0px 24px 0px;
  display: block;
}
.md-cookies .text {
  height: 100%;
  display: table;
}
.md-cookies .text .inner-text {
  display: table-cell;
  vertical-align: middle;
}
.md-cookies .text .inner-text p {
  color: #002233;
  line-height: 1.38;
}
@media screen and (min-width: 1100px) {
  .md-cookies .button-group .btn-primary {
    min-width: 140px;
    padding: 8px 10px 7px 10px;
  }
  .md-cookies .button-group .btn-primary:first-child {
    margin-right: 5px;
  }
}
@media screen and (min-width: 1300px) {
  .md-cookies .button-group .btn-primary {
    min-width: 180px;
    padding: 8px 20px 7px 20px;
  }
  .md-cookies .button-group .btn-primary:first-child {
    margin-right: 16px;
  }
}
@media screen and (max-width: 1099px) {
  .md-cookies .button-group .btn-primary {
    min-width: 120px;
    padding: 8px 10px 7px 10px;
  }
}
@media screen and (max-width: 991px) {
  .md-cookies .col-lg-8 {
    margin-bottom: 20px;
  }
  .md-cookies .col-lg-8 .text {
    display: block;
  }
  .md-cookies .col-lg-8 .text .inner-text {
    display: block;
  }
  .md-cookies .col-lg-8 .text .inner-text p {
    text-align: center;
  }
  .md-cookies .col-lg-4 {
    text-align: center;
  }
}

.bl-about {
  background: url(../images/bg-form-register.png) no-repeat right bottom 0px;
  padding: 15px 0px 50px;
  margin: 0px 0px 20px;
}
.bl-about .text-about {
  margin: 0px 0px 50px;
}
.bl-about .text-about h1 {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.5;
  color: #002233;
  margin: 0px 0px 28px;
}
.bl-about .text-about .excerpt p {
  margin: 0px 0px 34px;
}
.bl-about img {
  width: 100%;
  height: auto;
}
@media screen and (min-width: 768px) {
  .bl-about {
    background: url(../images/bg-form-register.png) no-repeat right top 0px;
  }
  .bl-about .text-about {
    margin: 56px 0px 0px;
  }
}
@media screen and (max-width: 767px) {
  .bl-about h1 {
    font-size: 36px;
  }
}

.md-team {
  background: #fff;
  border-radius: 8px;
  padding: 24px 24px 47px 24px;
}
.md-team .top {
  position: relative;
  margin: 0px 0px 41px;
}
.md-team .top .avatar {
  width: 136px;
  height: 136px;
  border-radius: 2px;
  display: inline-block;
  float: left;
  -webkit-background-size: 100% auto;
  background-size: 100% auto;
  position: absolute;
  top: -48px;
  left: 0px;
}
.md-team .top .avatar img {
  width: 100%;
  height: auto;
  border-radius: 2px;
}
.md-team .top .information {
  display: inline-block;
  float: left;
  width: -webkit-calc(100% - 160px);
  width: calc(100% - 160px);
  margin: 0px 0px 0px 160px;
}
.md-team .top .information h2 {
  font-size: 32px;
  line-height: 1.5;
  color: #002233;
  margin: 0px 0px 1px;
}
.md-team .top .information .meta {
  margin-top: -6px;
}
.md-team .top .information .meta .sub-title {
  float: left;
  margin: 6px 0px 10px;
}
.md-team .top .information .meta .sub-title p {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: #002233;
}
.md-team .excerpt p {
  font-size: 14px;
  line-height: 1.71;
  margin-bottom: 25px;
}
.md-team .excerpt p.sub-title {
  line-height: 1.75;
  color: #004466;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}
.md-team .excerpt ul li {
  margin-bottom: 8px;
  position: relative;
  padding: 0px 0px 0px 24px;
  line-height: 1.38;
  font-size: 16px;
}
.md-team .excerpt ul li:before {
  content: "";
  display: block;
  width: 8px;
  background: #004466;
  border-radius: 50%;
  height: 8px;
  position: absolute;
  top: 7px;
  left: 0px;
}
@media screen and (min-width: 992px) {
  .md-team .top .information .information h2 {
    font-size: 28px;
  }
  .md-team .top .information .meta .smd-social {
    float: right;
  }
}
@media screen and (max-width: 992px) {
  .md-team .top .information .information h2 {
    font-size: 28px;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .md-team .top .avatar {
    width: 120px;
  }
  .md-team .top .information {
    width: -webkit-calc(100% - 135px);
    width: calc(100% - 135px);
    margin: 0px 0px 0px 135px;
  }
}
@media screen and (max-width: 576px) {
  .md-team .top .avatar {
    width: 106px;
    height: 106px;
    position: relative;
    bottom: auto;
    left: auto;
  }
  .md-team .top .information {
    margin: 0px 0px 0px 24px;
    width: -webkit-calc(100% - 130px);
    width: calc(100% - 130px);
  }
  .md-team .top .information h2 {
    font-size: 25px;
  }
  .md-team .top .information .meta .smd-social {
    padding-top: 12px;
    float: left;
  }
}
@media screen and (max-width: 480px) {
  .md-team .top .avatar {
    width: 96px;
    height: 96px;
  }
  .md-team .top .information {
    margin: 0px 0px 0px 24px;
    width: -webkit-calc(100% - 120px);
    width: calc(100% - 120px);
  }
  .md-team .top .information h2 {
    font-size: 25px;
  }
  .md-team .top .information .meta .smd-social {
    padding-top: 7px;
  }
}

.bl-community h2 {
  text-align: center;
  line-height: 1.5;
  font-size: 40px;
  color: #002233;
  margin-bottom: 48px;
}
.bl-community .signup {
  padding: 60px 30px 100px 0px;
}
.bl-community .social {
  padding: 60px 0px 100px 30px;
}
.bl-community .social .smd-social {
  padding-top: 3px;
}
.bl-community h3 {
  font-size: 32px;
  line-height: 1.5;
  margin-bottom: 8px;
  color: #006aff;
}
.bl-community .text {
  margin-bottom: 32px;
}
.bl-community .text p {
  color: #004466;
  font-size: 14px;
  line-height: 1.43;
}
.bl-community .text p span {
  font-weight: 600;
  display: block;
}
.bl-community .smd-frm-signup form {
  position: relative;
}
.bl-community .smd-frm-signup form .btn-signup {
  position: absolute;
  right: 0px;
  top: 0px;
}
.bl-community .smd-frm-signup form .form-control {
  height: 48px;
  padding: 0px 135px 0px 8px;
}
@media screen and (min-width: 768px) {
  .bl-community .row .col-md-6:hover {
    background: #ffffff;
    cursor: pointer;
  }
}
@media screen and (min-width: 992px) {
  .bl-community .signup {
    padding: 120px 90px 120px 0px;
    max-width: 450px;
    float: right;
  }
  .bl-community .social {
    padding: 120px 0px 120px 90px;
    max-width: 450px;
    float: left;
  }
}
@media screen and (min-width: 1215px) {
  .bl-community .col-md-6 {
    padding: 0px;
  }
  .bl-community .signup {
    max-width: 607px;
  }
  .bl-community .social {
    max-width: 607px;
  }
}
@media screen and (max-width: 1214px) {
  .bl-community h3 {
    font-size: 28px;
  }
}
@media screen and (max-width: 767px) {
  .bl-community h2 {
    font-size: 28px;
    margin-bottom: 24px;
  }
  .bl-community h3 {
    font-size: 24px;
  }
  .bl-community .social {
    padding: 20px 0px 25px 0px;
  }
  .bl-community .signup {
    padding: 20px 0px 25px 0px;
  }
}

/*.bl-team {
    padding: 268px 0px 200px;
  }*/
.bl-team {
  padding: 68px 0px 68px;
}
.bl-team .md-team {
  height: 100%;
}
@media screen and (max-width: 767px) {
  .bl-team {
    padding: 120px 0px 30px;
  }
  .bl-team .col-md-6 {
    margin-bottom: 60px;
  }
}

.page-who-we-are .md-header-desktop {
  padding: 24px 0px 48px;
}
.page-who-we-are .page-main {
  padding: 48px 0px 0px;
}
@media screen and (max-width: 767px) {
  .page-who-we-are .page-main {
    padding: 48px 0px 52px;
  }
}

.height_100 {
  height: 100px;
}
.icon_color {
  color: #0059b3;
  font-size: 20px;
}
.font-20 {
  font-size: 20px;
}
.contact-img {
  width: 80%;
  padding-bottom: 20px;
}

/* ----------------- Home Css Start Here ---------------- */

.footer_head {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 30px;
}
.footer_p {
  color: #ebf3ff;
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 200;
  line-height: 18px;
}
.a_footer {
  color: #ebf3ff;
}

.ftr_link {
  line-height: 28px;
}
.ftr_link a {
  color: #ebf3ff;
  font-size: 14px;
}
.ftr_link a:hover {
  color: #ebf3ff;
  text-decoration: none;
}
.footer-copyright {
  color: #8f9db2;
  font-size: 12px !important;
}
.footer_li li {
  display: inline;
  padding: 0 0px 0px 20px;
}
.footer_li li a {
  color: #ebf3ff;
  font-size: 12px !important;
  font-weight: 400 !important;
}
.footer_li li a:hover {
  text-decoration: none;
}

.smd-social .social_med {
  display: inline;
}
.social_med a {
  color: #1d2839;
  background: #ebf3ff;
  border-radius: 99%;
  font-size: 16px;
  padding: 8px 5px 8px 9px;
  margin: 4px;
}

.social_med a:hover {
  color: #1d2839;
  text-decoration: none;
}
.logo {
  width: 230px;
}
.nav_bar_b4 {
  font-size: 14px;
  text-transform: uppercase;
}
h3.hero_hedading {
  font-size: 33px;
  font-weight: 700;
  color: #102440;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #0081e4 !important;
  border-bottom: 8px solid #0081e4 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  background-color: transparent !important;
}
.nav-link:hover {
  border: none;
}
li.nav-item a {
  color: #1d2839;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
}
.home_bg {
  background-image: url(img/bg_hero.png);
  background-size: cover;
  background-position: -360px -210px;
  background-repeat: no-repeat;
}

::placeholder {
  color: #c5c5c5 !important;
}
.nav-tab-bg {
  background: #fff;
  padding-top: 20px;
  box-shadow: -44px 55px 44px #16212d1c;
}
.main_bg {
  background-color: #f9fcff !important;
  padding: 23px 40px 89px !important;
  background-image: url(img/footer_22.png);
  background-repeat: no-repeat;
  background-position: -23% 154%;
  background-size: 27%;
}

.how_it_work {
  padding: 40px 0 0px 0;
}
.p_90 {
  padding: 80px 0 90px 190px;
}
.hiw_heading {
  letter-spacing: 0px;
  color: #1d2839;
  font-size: 35px;
  font-weight: 600;
  position: relative;
}
.padd {
  padding: 70px 50px 100px;
}
.padd_new {
  padding: 20px 3em;
}
.p_txt {
  color: #102440;
  font-size: 14px;
  line-height: 28px;
}
.mt-12em {
  margin-top: 8em;
}
.heading_new {
  color: #24436d;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 40px;
}
.top_footer {
  background: #102440;
  /* padding: 0 150px; */
}
.app__img {
  object-fit: contain;
  max-height: 485px;
}
.top__footerText {
  margin-top: 20px;
}
.footer_top_logo {
  width: 90%;
  margin: 13px;
  border-radius: 10px;
  border: 1px solid #fff !important;
}
.top_footer_heading {
  font-size: 44px;
  color: #fff;
  font-weight: 600;
}
.top_footer_p {
  font-size: 24px !important;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-top: 17px;
}
.top_footer_images {
  margin-top: 22px;
}
.mt_12em {
  margin-top: 9em;
}

.bgtext {
  position: relative;
  padding-left: 50px !important;
}

.bgtext:before {
  margin-top: -25px;
  content: "1.";
  position: absolute;
  color: #eff5f6;
  font-family: "Poppins";
  top: 0;
  left: 0;
  z-index: 0;
  font-size: 250px;
  font-weight: 700;
}
.bgtext_2 {
  position: relative;
  padding-left: 50px !important;
}

.bgtext_2:before {
  margin-top: -25px;
  content: "2.";
  position: absolute;
  color: #eff5f6;
  font-family: "Poppins";
  top: 0;
  left: 0;
  z-index: 0;
  font-size: 250px;
  font-weight: 700;
}

.bgtext_3 {
  position: relative;
  padding-left: 50px !important;
}

.bgtext_3:before {
  margin-top: -25px;
  content: "3.";
  position: absolute;
  color: #eff5f6;
  font-family: "Poppins";
  top: 0;
  left: 0;
  z-index: 0;
  font-size: 250px;
  font-weight: 700;
}

.hiw_p{
  position: relative;
  font-family: "Poppins";
  font-size: 16px;
  padding: 0 80px 0 0;
}

.developers p, ol li {
  font-family: "Poppins";
  font-size: 16px;
}


.slider_heading {
  color: #1d2839;
  font-weight: 600;
  font-size: 15px;
}
.box {
  box-shadow: 14px 55px 44px #16212d1c;
  border-radius: 2px;
  margin: 8px 8px 80px 8px;
  background: #fff;
  min-height: 240px;
}
.box-txt {
  color: #212833;
  font-size: 10px;
}
.box-num {
  color: #102440 !important;
  font-size: 16px;
  font-weight: 500;
}
.w50 {
  width: 47.4%;
}
.slick__carousel {
  background-color: #fff;
  padding-bottom: 100px;
  margin-top: 40px !important;
}

/* the slides */
.slick-slide {
  margin: 0 8px;
}

.slick-slider {
  margin: -13px;
}
/* the parent */
.slick-list {
  height: 400px;
}
/* i.fa.fa-chevron-left {
    position: absolute;
    top: 50%;
    left: 10px;
    z-index: 9999;
  } */

i.fa.fa-chevron-left {
  position: absolute;
  top: 30%;
  left: 10px;
  z-index: 9999;
  font-size: 0;
  background-image: url(img/left-arrow.png);
  height: 120px !important;
  width: 50px;
  background-size: 50px;
  background-repeat: no-repeat;
}
i.fa.fa-chevron-right {
  background-image: url(img/right-arrow.png);
  height: 120px !important;
  width: 50px;
  background-size: 50px;
  background-repeat: no-repeat;
  font-size: 0;
}
i.fa.fa-chevron-right {
  position: absolute;
  right: 10px;
  top: 30%;
  z-index: 9999;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
}
.padd_new {
  padding: 20px 6em !important;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .padd_new {
    padding: 20px 2em;
  }
  .main_bg {
    padding: 20px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .logo img {
    width: 230px;
  }
  .main_bg {
    padding: 0px !important;
  }
  .padd {
    padding: 50px 50px;
  }
  .bgtext_2:before,
  .bgtext:before {
    margin-top: 0px;
  }
  .padd_new {
    padding: 20px 2em;
  }
  .mt_12em {
    margin-top: 50px;
  }
  .page-main {
    padding: 48px 0px 0;
  }
  .bgtext:before,
  .bgtext_2:before {
    font-size: 150px;
  }
}

/* ------------- About Us Css -------------- */

.about_us_bg {
  background-image: url("img/11.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 10em 0;
}

.contact_bg {
  background-image: url(img/12.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 7em 0 14em 0;
  height: 91vh;
}

.about_us_bg h3 {
  font-size: 30px;
  color: #fff;
  line-height: 50px;
  font-family: Poppins;
  font-weight: 700;
}
.body_bg {
  background-image: url(img/Group7903.png);
  background-repeat: no-repeat;
  background-position: 111% -3%;
  padding: 7em 0;
  background-size: 28%;
}
.about_p {
  color: #102440;
  font-size: 20.5px;
  line-height: 30px;
  margin-top: 20px;
}
.about_heading {
  color: #102440;
  font-size: 36px;
  font-weight: 700;
}
.img_about {
  width: 200px;
}
.name_about {
  color: #102440;
  font-weight: 600;
  font-size: 25px;
  margin-top: 25px;
}
.name_about i {
  background: #008aff;
  padding: 8px 9px;
  border-radius: 100px;
  font-size: 17px;
  color: #fff;
}
.a_design {
  font-size: 18px;
  color: #008aff;
}
.padd-top {
  padding-top: 10em;
}
.top_footer_heading_about {
  font-size: 71px;
  color: #fff;
  font-weight: 600;
}
p.top_footer_p_about {
  font-size: 28px;
  color: #fff;
  font-weight: 600;
  padding: 1em 0;
}
.mt_7em {
  margin-top: 7em;
}
.about_ftr {
  padding: 8em 0 !important;
}
.about_ftr_btn {
  background: #0081e4 !important;
  padding: 13px 74px !important;
  font-weight: 500 !important;
}
.top_footer_about {
  background: #102440;
  padding: 1em 0 5em 0;
  background-image: url("../../assets/images/footer_bg.png");
  background-repeat: no-repeat;
  background-position: -15% 71%;
}
.side_img {
  background-image: url("img/signup.png");
}
.sml_txt {
  font-size: 12px;
  color: #000;
  margin-top: 10px;
}
.footer_btn {
  font-size: 12px !important;
  font-weight: 300 !important;
  padding: 12.2px 10px !important;
  border-radius: 0 !important;
  min-height: 45px;
}
.footer_input {
  font-size: 12px !important;
  min-height: 45px;
  color: #24436d;
}
.page-footer ::placeholder {
  color: #24436d !important;
}
.main_bg_about {
  background-color: #f9fcff;
  padding: 100px 0 140px !important;
}

.img_fttr {
  position: absolute;
  width: 440px;
  left: -140px;
  margin-top: -123px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .footer_btn {
    font-size: 9px;
    font-weight: 300;
    padding: 15.2px 10px;
    border-radius: 0;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .about_us_bg {
    padding: 5em 0;
  }
  .top_footer_heading_about {
    font-size: 51px;
  }
  .padd-top {
    padding-top: 3em;
  }
  .padd-top {
    padding-bottom: 5em;
  }
  .body_bg {
    padding: 0em 0;
    background: transparent !important;
  }
  .top_footer_heading_contact {
    font-size: 51px !important;
  }
  .contact_div {
    margin-left: 0 !important;
  }
  .about_us_bg h3 {
    font-size: 20px;
    color: #fff;
    line-height: 30px;
    font-weight: 500;
  }
}

.top_footer_heading_contact {
  font-size: 71px;
  font-weight: 700;
}
.top_footer_p_contact {
  font-weight: 700;
  font-size: 28px;
}
.form_design {
  background: #fff;
  padding: 20px;
}
.get_in_tuch {
  color: #24436d;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
}
.contact_div_txt {
  font-size: 21px;
  margin-right: 20px;
  color: #fff;
}
.contact_div {
  font-size: 15px !important;
  color: #fff !important;
  font-weight: 200 !important;
  text-align: right !important;
  margin-right: 20px;
  font-family: "Poppins" !important;
}
.btn_image {
  margin-left: -39px;
  margin-top: -7px;
}

.logo__img {
  margin-bottom: 25px !important;
}

.p_txt {
  font-size: 14px;
  font-family: "Poppins";
  font-weight: 400;
}

.new__padding {
  padding-right: 56px !important;
}

.how-it-works {
  margin-top: 70px;
}

.tab__transition {
  transition: all 0.4s ease-out;
}

#companies-tab:hover {
  border: none;
}
#profile-tab:hover {
  border: none;
}
#home-tab:hover {
  border: none;
}

.investor__formInput {
  border: 1px solid #e2e2e2 !important;
  height: 40px !important;
}

.broker__formInput {
  border: 1px solid #e2e2e2 !important;
  height: 40px !important;
}

.field {
  margin: 0 0 8px 0 !important;
  padding: 0 !important;
}
.liveDeals__button {
  height: 39px !important;
  text-align: center !important;
  padding-top: 9px !important;
  vertical-align: middle;
  margin-top: -2px !important;
  margin-left: -1px !important;
}

.logo__img {
  object-fit: contain;
  /* height: 32px; */
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 992px) {
  .logo__img {
    height: unset;
    object-fit: unset;
  }
}
.file-upload > label > p {
  color: white;
}

.app__sidebar {
  background-color: #f7f7f7 !important;
  width: 280px !important;
}

.sidebar__item {
  padding: 18px 0 18px 7px;
  border-bottom: 1px solid #ececec;
  text-align: left;
}

.sidebar__item a {
  padding-left: 8px;
  color: rgb(39, 39, 39) !important;
  font-family: "Poppins";
  font-weight: 300;
}

.sidebar__item a:hover {
  text-decoration: none;
}

.sidebar__input {
  height: 45px !important;
  background-color: #ddd !important;
  border: 1px solid #ddd !important;
}

/* Page Context */
.pushable {
  transform: none !important;
  margin: 0px !important;
}

.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed !important;
}

.sidebar__closeIcon {
  cursor: pointer;
  position: absolute;
  top: -5px;
  z-index: 10000;
  font-size: 30px;
  font-family: "Poppins";
  font-weight: 600;
}

.sidebar__closeIcon {
  text-shadow: 10px 10px 10px rgb(139, 139, 139) !important;
  color: white !important;
}

.top_footer_heading_contact {
  color: #004466;
  font-family: "Poppins";
  margin-bottom: 10px;
}

.top_footer_p_contact {
  color: #004466;
  font-family: "Poppins";
  margin-bottom: 10px;
}

.topContact__footer {
  padding: 0 78px !important;
}

.about_ftr_btn {
  margin-top: 15px !important;
}

.contact__form {
  border-radius: 0px !important;
}

.contact__padding {
  margin-top: -5px;
  padding: 0 72px 0 62px !important;
}
.contact__input {
  border: 1px solid #e2e2e2;
  height: 45px;
  margin-top: 10px !important;
}
.contact__textArea {
  border: 1px solid #e2e2e2;
  margin-top: 10px !important;
}
.contact__margin {
  margin-top: 17px !important;
}

.contact__form {
  padding: 42px !important;
  margin-top: 10px;
}

.text-right {
  font-family: "Poppins" !important;
}

.contact_div_txt > a {
  color: white;
}

.contact_div_txt > a:hover {
  text-decoration: none;
}

.contact_div_txt:nth-child(2) {
  margin-top: 5px;
}

.contact_div {
  margin-top: 4px;
  font-family: "Poppins" !important;
}

.aboutUs__main {
  padding: 40px 78px !important;
  min-height: 314px;
}

.about__mainLower {
  padding: 0 78px !important;
  padding-top: 160px !important;
}

.sml_txt,
.about_p,
.about__singUpPara,
.about_heading {
  font-family: "Poppins" !important;
}

.about__people {
  padding: 0 92px !important;
  padding-top: 16px !important;
}
.linkedin__icon {
  width: 40px !important;
  height: 40px !important;
  vertical-align: middle !important;
  border-radius: 50%;
  /* padding: 20px; */
}

.about__email {
  border: 1px solid #e7e7e7 !important;
  height: 45px !important;
}

.about__singUpPara {
  font-size: 16px;
}
.about__footer {
  padding: 0 78px !important;
}

@media only screen and (max-width: 992px) {
  .about__footer {
    padding: unset !important;
  }
}

.profile__sidebar {
  margin-right: 5%;
}

.ctn-inner .nav-content .avatar {
  margin-top: 20px;
  margin-bottom: 20px;
}

.ctn-inner .nav-content .avatar .picture img {
  height: 75px !important;
  width: 75px;
  border-radius: 50%;
  object-fit: cover !important;
  margin-bottom: 10px;
  box-shadow: 0px 2px 12px #0000001a;
}

.ctn-inner .nav-content .menu a {
  text-decoration: none;
  font-family: "Poppins" !important;
}

.ctn-inner .nav-content .information {
  font-family: "Poppins" !important;
}

.ctn-inner .nav-content .menu a:hover {
  color: #235899;
}
.cstm-min-height {
  min-height: 60px;
}
@media only screen and (max-width: 1381px) {
  .profile__sidebar {
    margin-right: unset;
  }
}

@media only screen and (max-width: 1110px) {
  .profile__sidebar {
    margin-right: unset;
  }
  .page-header-loggedin .container .main-nav-desktop {
    margin-left: unset;
  }
}

.loggedIn__list li a {
  font-family: "Poppins" !important;
  text-decoration: none;
}
.loggedIn__sidebarDropdown {
  display: flex;
  justify-content: flex-start;
  margin-left: 3px;
  padding: 13px 0;
  border-bottom: 1px solid #ececec;
}

.loggedIn__sidebarDropdown button {
  font-family: "Poppins" !important;
  color: #272727;
  font-weight: 300;
  border: none;
  outline: 0;
}

.loggedIn__sidebarDropdown button:hover {
  background-color: transparent !important;
  border: none;
}
.loggedIn__sidebarDropdown .btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  background-color: transparent !important;
  border: none;
}

.loggedIn__sidebarDropdown .btn.focus {
  outline: none;
}

.nav_bar_b4 li a {
  font-family: "Poppins" !important;
  text-decoration: none;
}

.about__mainText > div > a {
  font-family: "Poppins" !important;
  text-decoration: none !important;
}

.about__mainTextBottom > div > a {
  font-family: "Poppins" !important;
  text-decoration: none !important;
}

.name_about {
  font-family: "Poppins" !important;
  margin-top: 10px;
  font-weight: 600;
}
.a_design {
  font-family: "Poppins" !important;
  text-decoration: none !important;
}
.top_footer_heading_about {
  padding-top: 20px;
}
.top_footer_p_about {
  font-family: "Poppins" !important;
  font-weight: 700;
  margin-top: 15px;
}

.about_ftr_btn {
  font-family: "Poppins" !important;
  font-size: 15px !important;
}

.contact__phoneIcon {
  -webkit-transform: scaleX(-1) !important;
  transform: scaleX(-1) !important;
}

.contact_div_txt > a:hover {
  color: white;
}

.contact__submitButton {
  margin-top: 20px;
}

.mobile__headerImg {
  margin-top: 15px;
}

.mobile__headerImg .information {
  border-bottom: 1px solid #ecf3f7;
  text-align: center;
  padding: 0px 0px 16px;
  margin: 0px 0px 33px;
}

.mobile__headerImg .information p span {
  display: block;
}
.mobile__headerImg .information p span.name {
  font-weight: 600;
  line-height: 1.5;
  color: #002233;
  font-size: 16px;
}
.mobile__headerImg .information p span.email {
  font-size: 14px;
  line-height: 1.43;
  color: #004466;
}

.mobile__headerImg .nav-content .avatar {
  margin: 0px auto 14px;
  position: relative;
  width: 60px;
  height: 60px;
}
.mobile__headerImg .nav-content .avatar img {
  width: 60px !important;
  height: 60px !important;
  border-radius: 50% !important;
  object-fit: fill !important;
  -webkit-box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.75);
}
.mobile__headerImg .nav-content .avatar .upload-picture {
  position: absolute;
  bottom: -13px;
  right: -7px;
}
.mobile__headerImg .nav-content .avatar .upload-picture .btn-upload {
  background: url(../images/ico-upload-picture.png) no-repeat center center #fff;
  width: 32px;
  height: 32px;
  display: block;
  cursor: pointer;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 34, 51, 0.1);
  box-shadow: 0 0 4px 0 rgba(0, 34, 51, 0.1);
}
.mobile__headerImg .nav-content .avatar .upload-picture .input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.title {
  color: #000000 !important;
}

.entity__input {
  background-color: #f4f5f6 !important;
  height: 43px !important;
  font-family: "Poppins" !important;
}

#year,
#month,
#day {
  background-color: #f4f5f6 !important;
  height: 43px !important;
  font-family: "Poppins" !important;
}

.entity__buttonAdd {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 6px 24px !important;
  background-color: #008aff !important;
  color: white !important;
  border-radius: 0 !important;
}
.entity__buttonEdit {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 6px 24px !important;
  background-color: #008aff !important;
  color: white !important;
  border-radius: 0 !important;
  border: none !important;
}
.entity__buttonDelete {
  font-family: "Poppins" !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  padding: 6px 24px !important;
  background-color: #008aff !important;
  color: white !important;
  border-radius: 0 !important;
  border: none !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .how-it-works {
    margin: 0 !important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .contact_bg {
    height: auto !important;
  }
  .img_fttr {
    display: none;
  }
  .top_footer_about {
    background-position: 5% 1%;
  }
  .slick-slide {
    margin: 0 0px;
  }
  .slick__carousel {
    padding-bottom: 0px;
  }
  .footer-copyright {
    text-align: center;
    font-size: 10px !important;
  }
  .footer_li li a {
    color: #ebf3ff;
    font-size: 10px !important;
  }
  .social_med a {
    padding: 8px 6px 8px 9px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0 5px 0 0;
    padding: 12px 16px;
  }
  .ant-tabs-nav-container-scrolling {
    padding-right: 30px;
    padding-left: 30px;
  }
  iframe  {
    width: 100%!important;
    height: 270px!important;
  }
  .contact_div {
    font-size: 18px !important;
    margin-left: 1.2em !important;
    text-align: left !important;
  }
  .contact_div_txt {
    font-size: 18px!important;
    margin-right: 20px;
    color: #fff;
    margin-bottom: 23px;
  }
}

.deal-inner .logo img {
  max-width: 170px;
  height: auto;
}

@media screen and (min-width: 1215px) {
  .md-top-deal .deal-inner {
    max-width: 100% !important;
  }
}

.rdt_TableCell {
  text-align: left !important;
}

i.huge.icon,
i.huge.icons {
  font-size: 2em;
}

.form-control {
  border: 1px solid #ced4da;
}

.contact__input {
  border: inherit !important;
  height: 45px;
  margin-top: 10px !important;
}
