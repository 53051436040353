$gray: #6e8b9a;

.form-control {
  border: 1px solid #ced4da;
  &.hasError {
    border-color: #ff0000;
    position: relatve;
  }
}

.invalid-feedback {
  &.invalid-entry {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
}
